import React, { useMemo, useState } from 'react';
import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';
import order_del_car from "../../../../assets/order-del-car.png";
import { FaCircleCheck } from "react-icons/fa6";
import { IoCalendarOutline } from "react-icons/io5";
import { GoDotFill } from "react-icons/go";
import { FaArrowLeft } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CancelOrder, GetOrderScheduleLogs } from '../../../../redux/store/action/unauth-action/order-action/order-action';
import dayjs from 'dayjs';
import { Button, Card, IconButton, Modal, Stack, TextField, Typography } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import { toast } from 'react-toastify';


const Your_Order = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { orderList, getorderlog } = useSelector(({ orderStates }) => orderStates);

    // const data = useMemo(() => orderList.orders ? orderList.orders.filter(row => row.status !== 'Delivered') : [], [orderList.orders]);
    const data = useMemo(() => orderList && orderList.orders ? orderList.orders.filter(row => row.status !== 'Delivered') : [], [orderList]);

    const columns = useMemo(
        () => [
            {
                accessorKey: 'orderid',
                header: 'Order ID',
                Cell: ({ row }) => (
                    <p>{row.original?.orderDetail?.id}</p>
                ),
            },
            {
                accessorKey: 'upcomingOrderRequiredDate',
                header: 'Delivery Date',
            },
            {
                accessorKey: 'status',
                header: 'Status',
            },
            {
                accessorKey: 'orderDetail',
                header: 'Quantity',
                Cell: ({ row }) => (
                    <p>{row.original?.orderDetail?.qty}</p>
                ),
            },
            {
                accessorKey: 'total',
                header: 'Amount',
                Cell: ({ row }) => (
                    <p>{row.original?.orderDetail?.total}</p>
                ),
            },
            {
                accessorKey: 'detail',
                header: 'Detail',
                Cell: ({ row }) => (
                    <div className='main_detail_button_holder'>
                        <button
                            style={{ backgroundColor: '#1563B1', cursor: 'pointer', border: '2px solid #1563B1', color: 'white', borderRadius: '5px', padding: 10 }}
                            onClick={() => orderdetail(row)}
                        >
                            Track Your order
                        </button>
                        <button
                            style={{ backgroundColor: '#C10100', cursor: 'pointer', border: '2px solid #C10100', color: 'white', borderRadius: '5px', padding: 10 }}
                            onClick={() => openModal(row)}
                        >
                            Cancel Order
                        </button>
                    </div>
                ),
            },
        ],
        [],
    );

    const table = useMaterialReactTable({
        columns,
        data
    });

    const [showorderdetails, setshoworderdetails] = useState(false)

    const resHandler = (data) => {
        if (data) { }
    }

    const orderdetail = (row) => {
        dispatch(GetOrderScheduleLogs(row?.original?.orderDetail?.id, resHandler))
        setshoworderdetails(true)
        // console.log(row.original);
        // console.log(row?.original?.orderDetail?.id);
    }

    const goback = () => {
        setshoworderdetails(false)
    }

    const [modalIsOpen, setIsOpen] = useState(false);
    const [orderid, setorderid] = useState(null);
    const [cancelorderreason, setcancelorderreason] = useState(null);

    function openModal(row) {
        setorderid(row?.original?.orderDetail?.id);
        setIsOpen(true);
    }

    function closeModal() {
        setcancelorderreason(null);
        setIsOpen(false);
    }

    function CancelOrderfunction() {
        if (!cancelorderreason) {
            toast.error("Please enter a Cancel Order Reason!");
        } else {
            let formData = {
                orderId: orderid,
                reason: cancelorderreason
            }
            dispatch(CancelOrder(formData, resHandler))
            setIsOpen(false);
        }
    }


    return (
        <>
            {/* Cancel Order Modal Start Here */}
            <Modal
                open={modalIsOpen}
                onRequestClose={closeModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Card
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 800,
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        borderRadius: 10,
                    }}
                >
                    <Typography
                        id="modal-title"
                        variant="h6"
                        sx={{
                            textAlign: "center",
                            color: "#1591c7",
                            fontWeight: "bold",
                            fontSize: "30px",
                            marginTop: "60px",
                            marginBottom: "60px",
                        }}
                    >
                        Are you sure you want to Cancel Order!
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={closeModal}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: "#fff",
                            backgroundColor: "#dc0000",
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <TextField
                            style={{ width: "90%" }}
                            id="outlined-multiline-static"
                            label="Cancel Order Reason"
                            multiline
                            minRows={4}
                            maxRows={8}
                            value={cancelorderreason}
                            onChange={(e) => setcancelorderreason(e.target.value)}
                        />
                    </div>

                    <Stack direction="row" justifyContent="center" spacing={7} p={4}>
                        <Button
                            variant="contained"
                            sx={{
                                width: "200px",
                                height: "50px",
                                borderRadius: "20px",
                                backgroundColor: "green",
                                "&:hover": {
                                    backgroundColor: "darkgreen",
                                },
                            }}
                            onClick={CancelOrderfunction}
                        >
                            Yes
                        </Button>
                        <Button
                            variant="contained"
                            sx={{
                                width: "200px",
                                height: "50px",
                                borderRadius: "20px",
                                backgroundColor: "red",
                                "&:hover": {
                                    backgroundColor: "darkred",
                                },
                            }}
                            onClick={closeModal}
                        >
                            No
                        </Button>
                    </Stack>
                </Card>
            </Modal>
            {/* Cancel Order Modal End Here */}

            {
                showorderdetails ?
                    (
                        <>
                            <div className='main_order_status_div'>

                                <div className='main_order_status_back_arrow_div'><FaArrowLeft onClick={goback} /></div>

                                <div className='inner_header_order_status_div'>
                                    <div className='inner_header_order_status_div1'>
                                        <div style={{ fontWeight: 600, fontSize: 18, color: "#1591C7" }}>Order status</div>
                                        <div style={{ fontWeight: 700, fontSize: 18 }}>Order Id : {getorderlog?.customerOrderId}</div>
                                        <div style={{ fontSize: 15 }}>Your order is expected to be deliver on {getorderlog?.requiredDate}</div>
                                    </div>
                                    <div className='inner_header_order_status_div2'>
                                        <img src={order_del_car} alt="" />
                                    </div>
                                </div>

                                <div className='inner_body_order_status_div'>
                                    {getorderlog?.logs.map((logs, index) => (
                                        <div key={index} className='inner_body_order_loaction_div'>
                                            <div className='inner_body_order_loaction_div1'>
                                                <div><FaCircleCheck style={{ color: "#03a07b", fontSize: "1.2rem" }} /></div>
                                                <div>
                                                    <div style={{ fontSize: "1.1rem" }}>{logs?.statusDescription}</div>
                                                </div>
                                            </div>
                                            <div className='inner_body_order_loaction_div1'>
                                                <div>{dayjs(logs?.createdOn).format('DD-MM-YYYY hh:mm A')}</div>
                                                <div><IoCalendarOutline style={{ color: "#1591C7" }} /></div>
                                            </div>
                                        </div>
                                    ))}

                                </div>

                            </div>
                        </>
                    ) : (
                        <>
                            <MaterialReactTable table={table} />
                        </>
                    )
            }

        </>
    )
}

export default Your_Order;