// import axios from "axios"
import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import instance from "../../../../../libraries-configuration/axios-config/axios-config";
import apiCallMethods from '../../../../../libraries-configuration/api-methods/api-methods';
import { WALLETLIST } from '../../../reducer/unauth-reducer/wallet-reducer/wallet-reducer';



let endPoints = {
    getcustomerwallet: '/VirtualWallet/WalletGetById/',
};

const GetCustomerWallet = (customerId, resHandler) => {

    return async (dispatch) => {
        try {
            let response = await instance({
                method: apiCallMethods.GET,
                url: endPoints.getcustomerwallet + customerId,
            });

            console.log('Wallet List data:', response)
            let { status, data } = response;

            if (status === 200) {
                if (data.statusCode === 200) {

                    dispatch({
                        type: WALLETLIST,
                        payload: data?.data
                    })
                    resHandler(response)
                    // toast.success(data?.message)

                } else {
                    toast.error(response?.data?.message)
                }
            }

        } catch (error) {
            resHandler(error?.response?.data)
            console.log(error);
            toast.error(error?.response?.data?.message); // Corrected toast method
        }
    };
};




export {
    GetCustomerWallet,
};

<ToastContainer />; // Place the ToastContainer outside of functions
