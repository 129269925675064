import React, { useEffect, useMemo, useState } from 'react';
import "./Order-List.css";
import { Link, useNavigate } from "react-router-dom";
import cylinder_img from "../../../assets/Group 11613@3x.png";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Scheduled from "./Scheduled/Scheduled";
import In_Process from "./In-Process/In-Process";
import Past from "./Past/Past";
import { useDispatch, useSelector } from 'react-redux';
import { GetCustomerScheduledOrder } from '../../../redux/store/action/unauth-action/order-action/order-action';


function Order_List() {


  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { authuserdetail } = useSelector(({ authStates }) => authStates);

  const resHandler = (data) => {
    if (data) {
      // console.log(data);
    }
  }


  useEffect(() => {
    dispatch(GetCustomerScheduledOrder(authuserdetail?.customer?.customerId, resHandler))
  }, [dispatch, authuserdetail?.customer?.customerId]);

  return (
    <>
      <div className="main_order_list_div">

        <div className="main_order_list_tabs_section_div">
          <div><Link to="/My-Profile">My Profile</Link> </div>
          <div className="order_list_tab_active"><Link to="/order-list">Order list</Link></div>
          <div><Link to="/manage-address">Manage Address</Link> </div>
          <div><Link to="/Manage-Cards">Manage Cards</Link> </div>
          <div><Link to="/wallet">Wallet</Link> </div>
        </div>

        <div className="main_order_list_cards_div">

          <Tabs>

            <TabList>
              <Tab>Scheduled</Tab>
              <Tab>In Process</Tab>
              <Tab>Past</Tab>
            </TabList>

            <TabPanel>
              <Scheduled />
            </TabPanel>

            <TabPanel>
              <In_Process />
            </TabPanel>

            <TabPanel>
              <Past />
            </TabPanel>

          </Tabs>

          {/* <div className="main_order_list_cards_inner_div">
            <div
              className="order_list_card_inner_div"
              style={{
                backgroundColor: "white",
                borderRadius: "100px",
                boxShadow: "0px 0px 5px lightgray",
                padding: 5,
              }}
            >
              <img src={cylinder_img} alt="" />
            </div>

            <div className="order_list_card_inner_div">
              <div className="order_list_card_inner_heading_div">Item name</div>
              <div className="order_list_card_inner_value_div">
                fast cylinder
              </div>
            </div>

            <div className="order_list_card_inner_div">
              <div className="order_list_card_inner_heading_div">Date</div>
              <div className="order_list_card_inner_value_div">12.3.2024</div>
            </div>

            <div className="order_list_card_inner_div">
              <div className="order_list_card_inner_heading_div">Status</div>
              <div
                className="order_list_card_inner_value_div"
                style={{ color: "#03a07b" }}
              >
                Scheduled
              </div>
            </div>

            <div className="order_list_card_inner_div">
              <div className="order_list_card_inner_heading_div">Quantity</div>
              <div className="order_list_card_inner_value_div">2x</div>
            </div>
          </div> */}

        </div>
      </div>
    </>
  );
}

export default Order_List;
