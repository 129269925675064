import React, { useMemo, useState } from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import CloseIcon from "@mui/icons-material/Close";
import { Button, Card, IconButton, Modal, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CancelSubscription, GetCustomerOrder, GetCustomerScheduledOrder } from '../../../../redux/store/action/unauth-action/order-action/order-action';

const Next_orders = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { scheduledorderList } = useSelector(state => state.orderStates);
    const { authuserdetail } = useSelector(state => state.authStates);
    const inactiveOrders = scheduledorderList?.filter(order => order?.isScheduleActive === true);
    // console.log("inactiveOrders");
    // console.log(inactiveOrders);

    const [open, setOpen] = useState(false);
    const [rowData, setRowData] = useState(null);

    const handleOpen = (row) => {
        // console.log(row?.original);
        setRowData(row?.original);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const resHandler = (data) => {
        if (data) { }
    };

    const cancelSubscriptionResHandler = (data) => {
        if (data?.data?.statusCode === 200 || data?.data?.succeeded === true) {
            handleClose();
            dispatch(GetCustomerScheduledOrder(authuserdetail?.customer?.customerId, resHandler));
            dispatch(GetCustomerOrder(authuserdetail?.customer?.customerId, resHandler));
        }
    };

    const handleYesClick = () => {
        const formData = {
            scheduleId: rowData?.recurringSchedulerId,
            customerId: authuserdetail?.customer?.customerId,
            isActive: false
        };

        dispatch(CancelSubscription(formData, cancelSubscriptionResHandler));
    };

    const data = useMemo(() => inactiveOrders || [], [scheduledorderList]);

    const columns = useMemo(
        () => [
            {
                accessorKey: 'orderId',
                header: 'Order ID',
            },
            {
                accessorKey: 'nextScheduleDate',
                header: 'Date',
                Cell: ({ row }) => (
                    <p>{dayjs(row?.original?.nextScheduleDate).format('MM-DD-YYYY')}</p>
                ),
            },
            {
                accessorKey: 'qty',
                header: 'Quantity',
            },
            {
                accessorKey: 'detail',
                header: 'Detail',
                Cell: ({ row }) => (
                    <div className='main_detail_button_holder'>
                        <button
                            style={{
                                backgroundColor: '#C10100',
                                cursor: 'pointer',
                                border: '2px solid #C10100',
                                color: 'white',
                                borderRadius: '5px',
                                padding: 10
                            }}
                            onClick={() => handleOpen(row)}
                        >
                            Cancel Subscription
                        </button>
                    </div>
                ),
            },
        ],
        [],
    );

    const table = useMaterialReactTable({
        columns,
        data
    });

    return (
        <>
            <Modal
                open={open}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Card
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 600,
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        borderRadius: 10,
                    }}
                >
                    <Typography
                        id="modal-title"
                        variant="h6"
                        sx={{
                            textAlign: "center",
                            color: "#1591c7",
                            fontWeight: "bold",
                            fontSize: "30px",
                            marginBottom: "60px",
                        }}
                    >
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: "#fff",
                            backgroundColor: "#dc0000",
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography
                        sx={{
                            color: "#1591c7",
                            fontSize: "20px",
                            fontWeight: "bold",
                            textAlign: "center",
                        }}
                    >
                        Do you want to cancel this Subscription?
                    </Typography>
                    <Stack direction="row" justifyContent="center" spacing={5} p={4}>
                        <Button
                            variant="contained"
                            sx={{
                                width: "200px",
                                height: "50px",
                                borderRadius: "20px",
                                backgroundColor: "green",
                                "&:hover": {
                                    backgroundColor: "darkgreen",
                                },
                            }}
                            onClick={handleYesClick}
                        >
                            Yes
                        </Button>
                        <Button
                            variant="contained"
                            sx={{
                                width: "200px",
                                height: "50px",
                                borderRadius: "20px",
                                backgroundColor: "red",
                                "&:hover": {
                                    backgroundColor: "darkred",
                                },
                            }}
                            onClick={handleClose}
                        >
                            No
                        </Button>
                    </Stack>
                </Card>
            </Modal>

            <MaterialReactTable table={table} />
        </>
    );
};

export default Next_orders;