import React, { useCallback, useRef, useState } from "react";
import './Manage-Address.css'
import { MdModeEdit } from "react-icons/md";
import { Link, useNavigate } from 'react-router-dom';
import { FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import order_del_car from "../../../assets/order-del-car.png";
import { FaCircleCheck } from "react-icons/fa6";
import { IoCalendarOutline } from "react-icons/io5";
import { GoDotFill } from "react-icons/go";
import { FaArrowLeft } from "react-icons/fa";
import { TextField } from '@mui/material';
import { FaRegCircle } from "react-icons/fa6";
import { AddAddress, SelectAddress, UpdateAddress } from '../../../redux/store/action/unauth-action/address-action/address-action';
import { GetCustomerDetails } from '../../../redux/store/action/auth-action/auth-action';
import { toast } from 'react-toastify';
import { FaMapLocationDot } from "react-icons/fa6";
import Modal from 'react-modal';
import { GoogleMap, useJsApiLoader, Marker, Autocomplete } from '@react-google-maps/api';


const containerStyle = {
    width: '600px',
    height: '500px'
};

const center = {
    lat: -3.745,
    lng: -38.523
};

const libraries = ['places'];

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

function Manage_Address() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const phoneNumber = localStorage.getItem("phoneNumber");

    const { userId, addressList, authuserdetail } = useSelector(({ authStates }) => authStates);

    const [showmanageaddress, setshowmanageaddress] = useState(false)
    const [modalboxname, setmodalboxname] = useState('')

    const [Type, setType] = useState('')
    const [Address, setAddress] = useState('')
    const [City, setCity] = useState('')
    const [province, setprovince] = useState('')
    const [UpdateAddressid, setUpdateAddressid] = useState('');

    // Map State And Ref Start Here 
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: "AIzaSyA4PO-2Fniyav15Hqg9a7tcDu9fFByJiF8",
        libraries
    });

    const [coords, setCoords] = useState({
        lat: Number(authuserdetail?.latitude) || center.lat,
        lng: Number(authuserdetail?.longitude) || center.lng
    });

    const [map, setMap] = useState(null);
    const [inputValue, setInputValue] = useState('');

    const autocompleteRef = useRef(null);
    const mapRef = useRef(null);
    // Map State And Ref End Here 

    const manageaddress = (boxname, address) => {
        setmodalboxname(boxname);
        // console.log(address);
        setshowmanageaddress(true)

        if (address) {
            setType(address?.type);
            setAddress(address?.address);
            setCity(address?.city);
            setprovince(address?.province);
            setUpdateAddressid(address?.id);
        }

    }

    const goback = () => {
        setType('');
        setAddress('');
        setCity('');
        setprovince('');
        setUpdateAddressid('');
        setshowmanageaddress(false)
        setIsOpen(false);
    }

    const SelectAddressfunction = (address) => {
        // console.log(address);
        dispatch(SelectAddress(address))
    }

    // API res Handler Function Start Here
    const resHandler = (data) => {
        if (data) {
            dispatch(GetCustomerDetails(userId))
            goback();
        }
    }

    // Add Address Function Start Here
    const addaddressfunction = () => {

        if (Type === '') {
            toast.error("Please enter a Type!");
        } else if (Address === '') {
            toast.error("Please enter a Address!");
        } else if (City === '') {
            toast.error("Please enter a City!");
        } else if (province === '') {
            toast.error("Please enter a province!");
        } else {

            let formData = {
                "phoneNumber": phoneNumber,
                "userGUID": userId,
                "address": Address,
                "city": City,
                "type": Type,
                "province": province,
                "longitude": String(coords.lng),
                "latitude": String(coords.lat)
            }

            dispatch(AddAddress(formData, resHandler))
        }

    }

    // Update Address Function Start Here
    const updateaddressfunction = () => {

        if (Type === '') {
            toast.error("Please enter a Type!");
        } else if (Address === '') {
            toast.error("Please enter a Address!");
        } else if (City === '') {
            toast.error("Please enter a City!");
        } else if (province === '') {
            toast.error("Please enter a province!");
        } else {
            let formData = {
                "customerAddressId": UpdateAddressid,
                "address": Address,
                "city": City,
                "type": Type,
                "province": province,
                "longitude": String(coords.lng),
                "latitude": String(coords.lat)
            }

            dispatch(UpdateAddress(formData, resHandler))
        }

    }



    // Modal Function Start Here
    const [modalIsOpen, setIsOpen] = useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    // Maps Function Start Here
    const onLoad = useCallback((map) => {
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);
        setMap(map);
    }, []);

    const onUnmount = useCallback(() => {
        setMap(null);
    }, []);

    // Update the handlePlaceChanged function to set a state flag when all values are obtained
    const handlePlaceChanged = () => {
        if (autocompleteRef?.current) {
            const place = autocompleteRef?.current?.getPlace();

            if (place?.geometry) {
                const location = place?.geometry?.location;
                setCoords({
                    lat: location?.lat(),
                    lng: location?.lng()
                });
                map?.panTo(location);

                setAddress(place?.formatted_address);

                const province = place?.address_components.find(component => component.types.includes('administrative_area_level_1'));
                if (province) {
                    setprovince(province?.long_name);
                }

                const city = place?.address_components.find(component => component.types.includes('locality'));
                if (city) {
                    setCity(city?.long_name);
                }

                // Check if all values are obtained
                if (location && place?.formatted_address && province && city) {
                    setIsOpen(false);
                }
            }
        }
    };

    return (
        <>
            <div className='main_order_list_div'>

                <div className='main_order_list_tabs_section_div'>
                    <div> <Link to='/My-Profile'>My Profile</Link></div>
                    <div> <Link to='/order-list'>Order list</Link></div>
                    <div className='order_list_tab_active'> <Link to='/manage-address'>Manage Address</Link></div>
                    <div> <Link to='/Manage-Cards'>Manage Cards</Link></div>
                    <div> <Link to='/wallet'>Wallet</Link></div>
                </div>
                {showmanageaddress ?
                    (
                        <div className='main_add_address_div'>

                            <div className='main_add_address_back_arrow_div'><FaArrowLeft onClick={goback} /> <span>{modalboxname}</span></div>

                            <div className='inner_header_add_address_div'>
                                <TextField
                                    id="outlined-basic"
                                    label="Type"
                                    variant="outlined"
                                    style={{ width: "100%" }}
                                    value={Type}
                                    onChange={(e) => setType(e.target.value)}
                                />
                                <div
                                    className="select_address_div"
                                >
                                    <FaMapLocationDot onClick={openModal} />
                                    <TextField
                                        id="outlined-multiline-static"
                                        label="Address"
                                        multiline
                                        minRows={4}
                                        style={{ width: "100%" }}
                                        value={Address}
                                        onChange={(e) => setAddress(e.target.value)}
                                        disabled
                                    />
                                </div>

                                <TextField
                                    id="outlined-basic"
                                    label="City"
                                    variant="outlined"
                                    style={{ width: "100%" }}
                                    value={City}
                                    onChange={(e) => setCity(e.target.value)}
                                    disabled
                                />

                                <TextField
                                    id="outlined-basic"
                                    label="province"
                                    variant="outlined"
                                    style={{ width: "100%" }}
                                    value={province}
                                    onChange={(e) => setprovince(e.target.value)}
                                    disabled
                                />

                                <div className='main_add_address_save_button_div'>
                                    {modalboxname === 'Update Address' ?
                                        <button onClick={updateaddressfunction}>Save</button>
                                        :
                                        <button onClick={addaddressfunction}>Save</button>
                                    }
                                </div>

                            </div>

                        </div>
                    ) : (
                        <div className='main_manage_address_div'>

                            <div className='main_add_address_section_div'>
                                <div className='inner_add_address_section_div'>
                                    <FaPlus onClick={() => manageaddress("Add Address", '')} />
                                </div>
                            </div>

                            {addressList.map((address, index) => (
                                <div key={index} className='main_manage_address_card_div'>
                                    <div className='main_manage_address_card_body_div' onClick={() => SelectAddressfunction(address)}>
                                        <div className='main_manage_address_card_header_heading_div'>{address.type}</div>
                                        <div> <b style={{ color: "black" }}>Address: </b> {address.address}</div>
                                        <div><b style={{ color: "black" }}>province: </b>{address.province}</div>
                                        <div><b style={{ color: "black" }}>City: </b>{address.city}</div>
                                    </div>
                                    <div className='main_manage_address_card_header_div'>
                                        <div className='main_manage_address_card_header_edit_div'><MdModeEdit onClick={() => manageaddress("Update Address", address)} /></div>
                                    </div>
                                </div>
                            ))}

                        </div>
                    )
                }
            </div>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >

                <div className="main_map_div">
                    {isLoaded ? (
                        <div className="inner_map_div">

                            <div className="main_map_search_input_and_button_div">
                                <Autocomplete
                                    onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
                                    onPlaceChanged={handlePlaceChanged}
                                >
                                    <input
                                        className='places-input'
                                        type='text'
                                        value={inputValue}
                                        onChange={(e) => setInputValue(e.target.value)}
                                    />
                                </Autocomplete>
                            </div>

                            <GoogleMap
                                // className="map"
                                mapContainerStyle={containerStyle}
                                center={coords}
                                zoom={10}
                                onLoad={onLoad}
                                onUnmount={onUnmount}
                            >
                                <Marker
                                    position={coords}
                                    draggable={true}
                                    onDragEnd={(e) => setCoords({ lat: e.latLng.lat(), lng: e.latLng.lng() })}
                                />
                            </GoogleMap>
                        </div>
                    ) : (
                        <h1>Loading...!</h1>
                    )}
                </div>

            </Modal>

        </>
    )
}

export default Manage_Address
