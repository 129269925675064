import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Main from '../../pages/Auth-Pages/Landing-page/main';
import Privacy_Policy from '../../pages/Auth-Pages/privacy-policy/privacy-policy';
import SignIn from '../../pages/Auth-Pages/Signin/SignIn';
import { OtpScreen } from '../../pages/Auth-Pages/OtpScreen/OtpScreen';
import { SignUp } from '../../pages/Auth-Pages/SignUp/SIgnUp';
import Terms_and_Condition from '../../pages/Auth-Pages/Terms-and-Condition/Terms-and-Condition';
import Account_Deactivation from '../../pages/Auth-Pages/Account-Deactivation/Account-Deactivation';



const AuthRoutes = () => {

    return (
        <>
            <Routes>
                <Route path='/' element={<SignIn />} />
                <Route path='/Otp' element={<OtpScreen />} />
                <Route path='/SignUp' element={<SignUp />} />
                <Route path='/landing' element={<Main />} />

                <Route path='/Privacy-Policy' element={<Privacy_Policy />} />
                <Route path='/Terms-and-Condition' element={<Terms_and_Condition />} />
                <Route path='/Account-Deactivation' element={<Account_Deactivation />} />
                {/* <Route path='/' element={< />} /> */}
            </Routes>
        </>
    )
}

export default AuthRoutes