// import axios from "axios"
import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import instance from "../../../../libraries-configuration/axios-config/axios-config";
import { GETCUSTOMERDETAILS, OTP } from "../../reducer/auth-reducer/auth-reducer";
import apiCallMethods from '../../../../libraries-configuration/api-methods/api-methods';

let endPoints = {
    login: '/Auth/CustomerLogin',
    otp: '/Auth/CustomerOTPVerify',
    signup: '/Auth/CustomerRegistration',
    getcustomerdetails: '/Customer/GetCustomerDetailsByPhoneNumber/',
    accountdeleted: '/User/SoftDeleteAccount',
};




const loginUser = (formData, resHandler) => {

    return async (dispatch) => {
        try {
            let response = await instance({
                method: apiCallMethods.POST,
                url: endPoints.login,
                data: formData
            });

            console.log('Login data:', response)
            let { status, data } = response;

            if (status === 200) {
                if (data.statusCode === 200) {

                    resHandler(response)
                    toast.success(data?.message)

                } else {
                    toast.error(response?.data?.message)
                }
            }

        } catch (error) {
            resHandler(error?.response?.data)
            console.log(error);
            toast.error(error?.response?.data?.message); // Corrected toast method
        }
    };
};

const UserOtp = (formData, resHandler) => {

    return async (dispatch) => {
        try {
            let response = await instance({
                method: apiCallMethods.POST,
                url: endPoints.otp,
                data: formData
            });

            console.log('Otp data:', response)
            let { status, data } = response;

            if (status === 200) {
                if (data.statusCode === 200) {


                    toast.success(data?.message);
                    resHandler(response);

                    localStorage.setItem("AuthToken", data?.data?.jwToken);
                    localStorage.setItem("RefreshToken", data?.data?.refreshToken);

                    setTimeout(() => {
                        dispatch({
                            type: OTP,
                            payload: data?.data
                        });
                        window.location.href = "/";
                    }, 2000);

                } else {
                    toast.error(data?.message)
                }
            }

        } catch (error) {
            resHandler(error?.response?.data)
            console.log(error);
            toast.error(error?.response?.data?.message); // Corrected toast method
        }
    };
};

const SignupUser = (formData, resHandler) => {

    return async (dispatch) => {
        try {
            let response = await instance({
                method: apiCallMethods.POST,
                url: endPoints.signup,
                data: formData
            });

            console.log('Signup User data:', response)
            let { status, data } = response;

            if (status === 200) {
                if (data.statusCode === 200) {
                    localStorage.setItem("phoneNumber", formData?.phoneNumber);
                    setTimeout(() => {
                        window.location.href = '/otp'
                    }, 2000);
                    resHandler(response)
                    toast.success(data?.message)

                } else {
                    toast.error(response?.data?.message)
                }
            }

        } catch (error) {
            resHandler(error?.response?.data)
            console.log(error);
            toast.error(error?.response?.data?.message); // Corrected toast method
        }
    };
};

const GetCustomerDetails = (userId) => {

    return async (dispatch) => {
        try {
            let response = await instance({
                method: apiCallMethods.GET,
                url: endPoints.getcustomerdetails + userId,
            });

            console.log('GetCustomerDetails User data:', response)
            let { status, data } = response;

            if (status === 200) {
                if (data.statusCode === 200) {

                    // toast.success(data?.message)

                    dispatch({
                        type: GETCUSTOMERDETAILS,
                        payload: data?.data
                    });

                } else {
                    toast.error(response?.data?.message)
                }
            }

        } catch (error) {
            console.log(error);
            toast.error(error?.response?.data?.message); // Corrected toast method
        }
    };
};

const LogoutUser = () => {
    return (dispatch) => {
        localStorage.clear();
        window.location.href = "/";
    };
};

const AccountDeleted = (formData, resHandler) => {

    return async (dispatch) => {
        try {
            let response = await instance({
                method: apiCallMethods.POST,
                url: endPoints.accountdeleted,
                data: formData
            });

            console.log('AccountDeleted data:', response)
            let { status, data } = response;

            if (status === 200) {
                if (data.statusCode === 200) {

                    resHandler(response)
                    toast.success(data?.message)

                } else {
                    toast.error(response?.data?.message)
                }
            }

        } catch (error) {
            resHandler(error?.response?.data)
            console.log(error);
            toast.error(error?.response?.data?.message); // Corrected toast method
        }
    };
};


export {
    loginUser,
    UserOtp,
    SignupUser,
    GetCustomerDetails,
    LogoutUser,
    AccountDeleted,
};

<ToastContainer />; // Place the ToastContainer outside of functions
