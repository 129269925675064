import React from "react";
import blue_logo from "../../../assets/Group 11600@3x.png";
// import { IoIosLogOut } from "react-icons/io";


function Terms_and_Condition() {

    return (
        <>
            <div className="main_privacy_policy_section_div">
                <div className="inner_body_privacy_policy_section_div">
                    <div className="main_header_section_div">
                        <div>
                            <img src={blue_logo} id="" />
                        </div>
                        <div className="main_login_div">
                            {/* LOGIN IN
                            <IoIosLogOut /> */}
                        </div>
                    </div>
                    <div className="main_banner_section_div">
                        Terms & Condition
                    </div>

                    <div className="date_section_div">BLUE APPLICATION TERMS AND CONDITIONS</div>

                    <div className="main_text_privacy_policy_section_div">
                        <div className="full_section__text_privacy_policy_section_div">
                            <div className="text_privacy_policy_inner_div">
                                <p>
                                    The provision of Energy Service(s) by “Project Blue” to the Customer (as defined below) shall be governed by the terms and conditions stated herein below.
                                </p>

                            </div>
                        </div>
                    </div>

                    <div className="main_text_privacy_policy_section_div">
                        <div className="full_section__text_privacy_policy_section_div">
                            <div className="text_privacy_policy_inner_heading_div">
                                1.	DEFINITIONS
                            </div>
                            <hr />
                            <div className="text_privacy_policy_inner_div">
                                <div>
                                    <ul>
                                        <li><b>“Affiliate(s)” </b> means any company or person that at any time during the term of this directly or indirectly Controls or is Controlled by, or is under common Control with a Party;</li>
                                        <li><b>“Account” </b> means a digital account created by a Customer on any PSO Application. </li>
                                        <li><b>“Bar Code” </b> means a unique identification strip that is placed on Project Blue Asset(s).</li>
                                        <li><b>“Blue Application” </b> means PSO or its Affiliate’s digital mobile application for Energy Service Delivery and associated services to Customer(s).</li>
                                        <li><b>“Conditions” </b> means the terms and conditions for the supply, delivery and use of PSO Assets  </li>
                                        <li><b>"Control" </b> (includes the term “Controlled”) and means the power to direct or cause the direction of the management and/or policies of a company, partnership, firm, or other entity or person, whether through ownership of its voting shares or by contract or otherwise including any merger, acquisition or disposal of another business. Without limiting the foregoing, for the purposes of this Agreement the ownership of shares carrying fifty percent (50%) or more of the voting rights or in case of less than (50%) voting rights, the exercise of management control in any manner (including through legislation or shareholder’s agreement) shall be deemed to constitute control;</li>
                                        <li><b>“Customer” </b> means the individual who places an Order for Energy Service Delivery through Blue Application.</li>
                                        <li><b>“Digicash” </b> means PSO’s digital wallet used for making virtual payments and which is subject to separate terms of use accepted by the Customer at the time of sign-up. </li>
                                        <li><b>“Delivery” </b> means delivery of the Project Blue Asset(s).</li>
                                        <li><b>“Delivery Address” </b> means the physical address communicated by the Customer through the Modes of Communication for Energy Service and delivery of Project Blue Assets.</li>
                                        <li><b>“Delivery Date” </b> means the approximate date for Energy Service delivery communicated through the Modes of Communication .</li>
                                        <li><b>“Energy Service” </b> means the delivery of Project Blue Assets and other energy related product and services provided by Project Blue in accordance with the Conditions stated herein.</li>
                                        <li><b>“LPG Cylinder” </b> means a cylinder of Liquified Petroleum Gas of Project Blue that is identifiable by a Bar Code.</li>
                                        <li><b>“Modes of Communication” </b> means and includes either singly or in combination the PSO Application(s), SMS, TAALUQ and PSO Website that shall be used as means of communication between PSO or its Affiliate(s) with the Customer(s). </li>
                                        <li><b>“Order” </b> means a request for delivery of PSO assets made by the Customer  through PSO Application(s).</li>
                                        <li><b>“Intellectual Property” </b> means all intellectual property rights arising from or associated with the following, whether created, protected or arising under the laws of Pakistan:</li>
                                        <li>trade names, trademarks and service marks (whether registered or unregistered), domain names and other internet addresses or identifiers, similar rights and applications to register any of the foregoing (collectively, <b>“Marks”</b>);</li>
                                        <li>copyrights (whether registered or unregistered) (collectively, <b>“Copyrights”</b> );</li>
                                        <li>know-how, inventions, discoveries, methods, processes, techniques, methodologies, formulae, algorithms, technical data, specifications, research and development information, technology, data bases and other proprietary or confidential information, including customer lists, but excluding any Copyrights that cover or protect any of the foregoing (collectively, <b>“Trade Secrets”</b> ); and</li>
                                        <li>any other proprietary, intellectual or industrial property rights of any kind or nature that do not comprise or are not protected by Marks, Copyrights or Trade Secrets.</li>
                                        <li><b>“Personal Data” </b> means inter alia names, phone numbers, CNIC, email address, residential address, payment details, Order details, location data, and any other information relating to an identified or identifiable individual, including through identification by means of online identifiers, device IDs, IP addresses or similar methods.</li>
                                        <li><b>“Project Blue Assets” </b> means an LPG Cylinder and other assets that PSO provides as part of its Energy Service. </li>
                                        <li><b>“Project Blue” </b> means PSO’s pilot project for Energy Service Delivery and associated services. </li>
                                        <li><b>“Project Blue Helpline” </b> means the helpline 0800-03000 available to Customer(s) for facilitation with Energy Service Delivery.</li>
                                        <li><b>“Project Blue Team” </b> means the personnel duly authorized by Project Blue to give effect to the Conditions stated herein.</li>
                                        <li><b>“PSO Applications” </b> means either single or collectively, as the context permits, the Blue Application, PSO Website, Digicash and any other application or website that may be introduced by PSO for use of the Energy Service.</li>
                                        <li><b>“QR Code” </b> means the unique digital identification code of Project Blue associated with each Order. </li>
                                        <li><b>“Refundable Order” </b> means </li>
                                        <li><b>“Refund Policy” </b> means the Project Blue refund policy as updated from time to time which shall be applicable for Refundable Order(s). </li>
                                        <li><b>“Territory” </b> means  the territorial jurisdiction within Pakistan  where  the Energy  Service will be provided.  </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="main_text_privacy_policy_section_div">
                        <div className="full_section__text_privacy_policy_section_div">
                            <div className="text_privacy_policy_inner_heading_div">
                                2.	REGISTERATION AND SERVICE TERMS
                            </div>
                            <hr />
                            <div className="text_privacy_policy_inner_div">
                                <div>
                                    <ul>
                                        <li>The Customer shall be required to sign-up and register for PSO Applications and create an Account using a valid Computerized National Identification Number (“CNIC”) or a phone number registered on the Customer’s CNIC. </li>
                                        <li>The Customer shall be responsible for ensuring that information provided at the time of registration of the Account is accurate including name, email address, telephone and Delivery Address. </li>
                                        <li>With respect to LPG Cylinder(s), the Customer upon registration shall be presented with an option to self-select a period for recurring Delivery either for new cylinders or for refill <b>(“Recurring Delivery Date”)</b>. All Order(s) shall however be subject to confirmation by Project Blue and it is understood that Project Blue shall retain the right to decline an Order or communicate an alternate Delivery Date based on availability of inventory. </li>
                                        <li>The Customer may cancel an Order via Modes of Communication two (02) days prior to the Delivery Date. For Refundable Order(s), the Customer will be refunded any pre-paid amount via the PSO Application or through any other method in accordance with the Refund Policy.</li>
                                        <li>If an Order is accepted by Blue Application, a confirmation shall be issued specifying either full or partial confirmation of the Order on the basis of availability and Project Blue’s capacity to fulfil the Order <b>(“Confirmation”)</b>.</li>
                                        <li>The Customer shall notify Project Blue of any errors, deficiencies or inaccuracies in a   Confirmation immediately upon receipt of Confirmation and Project Blue shall, if necessary, issue a corrected Confirmation. For the avoidance of doubt, Project Blue shall not be responsible for any errors, deficiencies or inaccuracies in a Confirmation that are not timely notified by the Customer. </li>
                                        <li>In the context of the Recurring Delivery Date selected by the Customer, the Customer shall receive a reminder notification two (02) days prior to the scheduled Delivery and the Customer shall have the option to either confirm the Order or delay the reminder to the next day. In case the reminder is continuously delayed at the option of the Customer and Delivery on the Recurring Delivery Date is not confirmed, Blue Application shall allow the Customer a further period of ten (10) as a final opportunity to either confirm an alternate date for Delivery or notify Project Blue to make collection. </li>
                                        <li>Provided there are no Project Blue Assets in a Customer’s possession and no pending confirmed Order(s) awaiting Delivery, a Customer may cancel the Account and unsubscribe from the Energy Service. </li>
                                        <li>If a Customer is in possession of any Project Blue Asset(s), the Customer shall be unable to cancel the Account until the Project Blue Asset(s) is returned to Project Blue in accordance with these Conditions.</li>
                                        <li>For collection of the Project Blue Asset(s), Project Blue shall initiate periodical prompts via the PSO Applications or Modes of Communication communicating a time for collection of the Project Blue Asset(s). A thorough inspection will be conducted by the Project Blue Team and provided there is no damage, tampering or misuse of the Project Blue Asset(s), the full amount of Security Deposit shall be reimbursed to the Customer. </li>
                                        <li>All complaints will be directed through the PSO Applications or Modes of Communication. </li>
                                        <li>If these Conditions are breached in any manner, Project Blue shall have the right to withhold partially or fully the Security Deposit and in its discretion exercise the right to initiate legal action against the Customer. </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="main_text_privacy_policy_section_div">
                        <div className="left_text_privacy_policy_section_div">
                            <div className="text_privacy_policy_inner_heading_div">
                                3.	PRICE AND PAYMENT FOR LPG
                            </div>
                            <hr />
                            <div className="text_privacy_policy_inner_div">
                                <div>
                                    <ul>
                                        <li>It is understood and agreed by the Customer that the price of LPG is notified by the Oil and Gas Regulatory Authority of Pakistan (“OGRA Price”) and is therefore subject to change from time to time.</li>
                                        <li>The Customer agrees to pay the prevailing OGRA Price and the additional service fees charged by Project Blue at its discretion for Delivery of the LPG Cylinder(s).</li>
                                        <li>Once an Order is placed via PSO Application(s), an invoice shall be generated on the respective PSO Application that shall showcase the number of cylinders ordered, category (new cylinder or refill), security deposit payable of Rs. [insert] (only in case of first Order for new cylinder) and associated products and services. The Customer shall have the option to pay the invoice via Digicash or opt to pay cash on delivery or by other PSO Application(s) wallet. It shall be the Customer’s responsibility to ensure Digicash account or other PSO Application(s) account is topped up with sufficient funds for payment of the invoice. </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="right_text_privacy_policy_section_div">
                            <div className="text_privacy_policy_inner_heading_div">
                                4.	CUSTOMER OBLIGATIONS
                            </div>
                            <hr />
                            <div className="text_privacy_policy_inner_div">
                                <p>The Customer undertakes, agrees and represents as follows:</p>
                                <div>
                                    <ul>
                                        <li>The PSO Asset(s) shall only be for self-consumption.  </li>
                                        <li>The Customer shall be solely responsible for lawful use and consumption of PSO Asset(s) and undertakes to abide by general safety directions and any specific guidelines with respect to use of LPG Cylinders.  </li>
                                        <li>Store and use the LPG Cylinder in a safe and secure environment.  </li>
                                        <li>Shall bear all responsibilities, cost and risks with respect to handling, installation and operation of the PSO Asset(s).  </li>
                                        <li>All risks associated with the use and possession of the PSO Asset(s) shall transfer to the Customer upon Delivery by Project Blue. However, the ownership of PSO Asset(s) shall at all times remain Project Blue’s and the Customer shall not be permitted to engage with any third party. for the sale, lending or exchange of PSO Asset(s) (including refill of LPG Cylinder) within or outside the Territory limits.  </li>
                                        <li>The Customer shall be solely responsible for provision of accurate Personal Data and agrees that Project Blue shall reserve the right to cancel an Order without liability in case incorrect or discrepant information is provided. </li>
                                        <li>At the time of Delivery, the Customer shall present the QR Code on the invoice of the relevant Order and the Project Blue Team member shall  scan  the QR Code   to confirm Delivery of the Order. If no QR code is presented at the time of Delivery, Project Blue shall have the right to refuse Delivery.. If any of the foregoing  conditions are not met or the Customer is unavailable at the time of Delivery, the Delivery shall be deemed unsuccessful and the next date of Delivery shall be communicated by Project Blue via Blue Application and SMS.  </li>
                                        <li>The Customer shall in no event be permitted to transport the LPG Cylinder(s) and change the location of the LPG Cylinder (s) from the one where Project Blue made Delivery. However, in case of a change of address, the Customer shall be responsible for contacting PSO via TAALUQ for notifying the change. Project Blue shall in such event have the right to collect the LPG Cylinder(s) in the Customer’s possession.</li>
                                        <li>It is understood by the Customer that the LPG Cylinder(s) shall at all times remain PSO’s sole property. The Customer shall be liable for the prompt return of the LPG Cylinder(s) to PSO’s representative(s) who visit the Customer for collection.</li>
                                        <li>The Customer undertakes that it shall avoid argument and/or altercation with the Project Blue Team and in no event resist or refuse to return the Project Blue Asset(s).</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="main_text_privacy_policy_section_div">
                        <div className="left_text_privacy_policy_section_div">
                            <div className="text_privacy_policy_inner_heading_div">
                                5.	INTELLECTUAL PROPERTY
                            </div>
                            <hr />
                            <div className="text_privacy_policy_inner_div">
                                <p>All Intellectual Property pertaining to the Service, PSO Applications and all information, data and material created for the promotion, marketing and provision of LPG Cylinders to Customers shall solely vest in PSO and any infringement of PSO’s Intellectual Property in any manner shall be actionable under applicable laws. It is understood that the Customer is not permitted to copy, reproduce, republish, upload, post, transmit, distribute, modify or use Intellectual Property of PSO in any manner unless authorized by PSO for a specific purpose. </p>
                            </div>
                        </div>
                        <div className="right_text_privacy_policy_section_div">
                            <div className="text_privacy_policy_inner_heading_div">
                                6.	DATA PROTECTION AND CONFIDENTIALITY
                            </div>
                            <hr />
                            <div className="text_privacy_policy_inner_div">
                                <div>
                                    <ul>
                                        <li>The Customer hereby provides its consent and explicitly authorizes PSO to collect, record, use and process Customer’s Personal Data in connection with registration and use of PSO Applications and in the course of PSO performing the Service. PSO undertakes that Customer’s Personal Data shall be used in accordance with prevailing and applicable laws and solely for performance of the Service. The confidentiality and integrity of Personal Data shall be protected by PSO on best effort basis but it is agreed that PSO will not bear liability for any harm to Personal Data arising from communication line failure, software viruses in Customer devices, theft, destruction, alteration or misuse by third parties who are either permitted access by the Customer or obtain unauthorized access illegally.</li>
                                        <li>The Customer further agrees and authorizes disclosure of Personal Data on a need to know basis to those third parties (including affiliates, partners, subsidiaries, consultants, advisors and representatives) that shall be associated with PSO for execution of the Service. It is further understood that PSO shall have no liability for any disclosure of Personal Data made in compliance with applicable laws.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="main_text_privacy_policy_section_div">
                        <div className="left_text_privacy_policy_section_div">
                            <div className="text_privacy_policy_inner_heading_div">
                                7.	TERMINATION
                            </div>
                            <hr />
                            <div className="text_privacy_policy_inner_div">
                                <p>PSO shall have the right to terminate the Service without cause and at its convenience by providing reasonable prior notice to the Customer. In the event of termination, the Customer shall be permitted use of the Service until the Recurring Delivery Date but no further Delivery shall be made thereafter and the option of recurring Delivery shall automatically lapse. After termination of the Service, PSO shall promptly collect the LPG Cylinder(s) in the Customer’s possession and the date of collection shall be informed in advance. Security deposit will be returned in full to the customer at the time of LPG Cylinder recovery.</p>
                            </div>
                        </div>
                        <div className="right_text_privacy_policy_section_div">
                            <div className="text_privacy_policy_inner_heading_div">
                                8.	INDEMNIFICATION
                            </div>
                            <hr />
                            <div className="text_privacy_policy_inner_div">
                                <p>The Customer shall indemnify and hold harmless PSO its employees, affiliates, subsidiaries, directors, officers, shareholders, contractors and representatives (“Indemnified Parties”) from and against any losses, damages, liabilities, expenses (including reasonable fee of attorney) costs, claims, suits, demands, actions, causes of action, proceedings, judgments and charges arising from Customer’s breach of these Conditions, use of the Service and use of PSO Applications.</p>
                            </div>
                        </div>
                    </div>

                    <div className="main_text_privacy_policy_section_div">
                        <div className="left_text_privacy_policy_section_div">
                            <div className="text_privacy_policy_inner_heading_div">
                                9.	AMENDMENTS
                            </div>
                            <hr />
                            <div className="text_privacy_policy_inner_div">
                                <p>PSO shall have the right to amend these Conditions in its sole discretion and the amended Conditions shall be accessible to the Customer via PSO Applications. The amended Conditions shall become effective immediately upon publication on PSO Applications whether or not the same have been acknowledged by the Customer. It is the Customer’s sole responsibility to install updates and upgrades to the PSO Applications to remain aware of changes in the Conditions of use of the Service.</p>
                            </div>
                        </div>
                        <div className="right_text_privacy_policy_section_div">
                            <div className="text_privacy_policy_inner_heading_div">
                                10.	GOVERNING LAW
                            </div>
                            <hr />
                            <div className="text_privacy_policy_inner_div">
                                <p>The Conditions and the Service shall be governed by and construed in accordance with the laws of the Islamic Republic of Pakistan and the courts of Islamabad, Pakistan shall have exclusive jurisdiction.</p>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="main_footer_div">
                    <div className="inner_footer_div1">
                        <p>
                            <a href="/Privacy-Policy">
                                Privacy Policy
                            </a>
                        </p>
                        <hr />
                        <p>
                            <a href="/Terms-and-Condition">
                                Terms and Condition
                            </a>
                        </p>
                        <hr />
                        <p>Account Deactivation</p>
                    </div>
                    <div className="inner_footer_div2">
                        <p>© Copyright by PSO</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Terms_and_Condition;
