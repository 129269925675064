import React, { useMemo, useState } from 'react';
import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';


const In_house_cylinder = () => {

    const { AllCylinderStatusList } = useSelector(({ dashboardStates }) => dashboardStates);

    const data = useMemo(() => AllCylinderStatusList || [], []);

    // const data = useMemo(() => [
    //     {
    //         orderid: '00012',
    //         deliverydate: '12-5-2024',
    //         Status: 'On the way',
    //         quantity: 22,
    //         Amount: 300,
    //     },
    //     {
    //         orderid: '00013',
    //         deliverydate: '12-5-2024',
    //         Status: 'On the way',
    //         quantity: 22,
    //         Amount: 300,
    //     },
    //     {
    //         orderid: '00014',
    //         deliverydate: '12-5-2024',
    //         Status: 'On the way',
    //         quantity: 22,
    //         Amount: 300,
    //     },
    //     {
    //         orderid: '00015',
    //         deliverydate: '12-5-2024',
    //         Status: 'On the way',
    //         quantity: 22,
    //         Amount: 300,
    //     },
    //     {
    //         orderid: '00016',
    //         deliverydate: '12-5-2024',
    //         Status: 'On the way',
    //         quantity: 22,
    //         Amount: 300,
    //     },
    //     {
    //         orderid: '00017',
    //         deliverydate: '12-5-2024',
    //         Status: 'On the way',
    //         quantity: 22,
    //         Amount: 300,
    //     },
    // ], []);


    const columns = useMemo(
        () => [
            {
                accessorKey: 'id',
                header: 'Order ID',
            },
            {
                accessorKey: 'barcode',
                header: 'Barcode',
            },
            {
                accessorKey: 'deliverydate',
                header: 'Delivery Date',
                Cell: ({ row }) => (
                    <p>{dayjs(row.original?.createdOn).format('DD-MM-YYYY')}</p>
                ),
            },

        ],
        [],
    );

    const table = useMaterialReactTable({
        columns,
        data
    });


    return (
        <>
            <MaterialReactTable table={table} />
        </>
    )
}
export default In_house_cylinder
