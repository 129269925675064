import './App.css';
import AppRoutes from './routes/routes';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './redux/store/store';
import { ToastContainer } from 'react-toastify';
// import { useEffect } from 'react';
// import { generateToken, messaging } from './Firebase';
// import { onMessage } from 'firebase/messaging';
// import { RecuringOrderConfirmed } from './redux/store/action/unauth-action/order-action/order-action';


function App() {

  // const dispatch = useDispatch();

  // useEffect(() => {
  //   generateToken();
  //   onMessage(messaging, (payload) => {
  //     console.log('NOTIF:', payload)
  //     toast.success(payload?.notification?.body)
  //     // if (payload?.data) {
  //     // dispatch(RecuringOrderConfirmed(payload.data))
  //     // }
  //   })
  // }, []);

  return (
    <>
      <ToastContainer />
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <AppRoutes />
        </PersistGate>
      </Provider>
    </>
  );
}

export default App;
