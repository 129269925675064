import React, { useCallback, useRef, useState } from "react";
import './Signup.css'
import {
  Grid,
  Box,
  Card,
  TextField,
  Typography,
  Stack,
  Button,
  Link,
} from "@mui/material";
import Group116683x from "../../../assets/Group 11668@3x.png";
import Group116672x from "../../../assets/Group 11667@2x.png";
import Group116003x from "../../../assets/Group 11600@3x.png";
import appleplaystore from "../../../assets/AAsS@3x.png";
import en_badge_web_generic from "../../../assets/en_badge_web_generic@3x.png";
import { useDispatch, useSelector } from "react-redux";
import { SignupUser } from "../../../redux/store/action/auth-action/auth-action";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import Modal from "react-modal";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  Autocomplete,
} from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "400px",
};

const center = {
  lat: -3.745,
  lng: -38.523,
};

const libraries = ["places"];

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: "600px",
  },
};

export const SignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { authuserdetail } = useSelector(({ authStates }) => authStates);



  const [Name, setName] = useState(null);
  const [Email, setEmail] = useState(null);
  const [phoneNumber, setphoneNumber] = useState(null);
  const [Address, setAddress] = useState(null);
  const [City, setCity] = useState(null);
  const [province, setprovince] = useState(null);


  // Map State And Ref Start Here 
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyA4PO-2Fniyav15Hqg9a7tcDu9fFByJiF8",
    libraries
  });

  const [coords, setCoords] = useState({
    lat: Number(authuserdetail?.latitude) || center.lat,
    lng: Number(authuserdetail?.longitude) || center.lng
  });

  const [map, setMap] = useState(null);
  const [inputValue, setInputValue] = useState('');

  const autocompleteRef = useRef(null);
  const mapRef = useRef(null);
  // Map State And Ref End Here 


  const resHandler = (data) => {
    if (data) {
    }
  };

  const Register = () => {
    if (!Name) {
      toast.error("Please enter a Name!");
    } else if (!Email) {
      toast.error("Please enter a Email!");
    } else if (!phoneNumber) {
      toast.error("Please enter a Contact Number!");
    } else if (!Address) {
      toast.error("Please enter a Address!");
    } else {
      let formData = {
        "phoneNumber": phoneNumber,
        "name": Name,
        "address": Address,
        "email": Email,
        "latitude": String(coords?.lat),
        "longitude": String(coords?.lng),
        "dateOfBirth": "",
        "defaultLoc": "",
        "cnic": "",
        "cnicIssueDate": "",
        "cnicExpiryDate": "",
        "city": City,
        "type": "CU",
        "province": province,
        "base64Image": ""
      }

      // console.log(formData);
      dispatch(SignupUser(formData, resHandler))

      dispatch(SignupUser(formData, resHandler));
    }
  };

  // Modal Function Start Here
  const [modalIsOpen, setIsOpen] = useState(true);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  // Maps Function Start Here
  const onLoad = useCallback((map) => {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  // Update the handlePlaceChanged function to set a state flag when all values are obtained
  const handlePlaceChanged = () => {
    if (autocompleteRef?.current) {
      const place = autocompleteRef?.current?.getPlace();

      if (place?.geometry) {
        const location = place?.geometry?.location;
        setCoords({
          lat: location?.lat(),
          lng: location?.lng()
        });
        map?.panTo(location);

        setAddress(place?.formatted_address);

        const province = place?.address_components.find(component => component.types.includes('administrative_area_level_1'));
        if (province) {
          setprovince(province?.long_name);
        }

        const city = place?.address_components.find(component => component.types.includes('locality'));
        if (city) {
          setCity(city?.long_name);
        }

        // Check if all values are obtained
        if (location && place?.formatted_address && province && city) {
          setIsOpen(false);
        }
      }
    }
  };

  return (
    <>
      <Box
        sx={{
          backgroundImage: `url('${Group116683x}')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "100dvh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: { xs: 2, sm: 4 },
        }}
      >
        <Grid container spacing={2} justifyContent="center">
          <Grid
            item
            xs={6}
            md={6}
            lg={5}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mb: { xs: 4, md: 0 },
            }}
          >
            <img
              style={{ maxHeight: { xs: 300, md: 624 }, width: "100%", objectFit: "contain" }}
              loading="lazy"
              src={Group116672x}
              alt="Illustration"
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={7}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box sx={{ width: "100%", maxWidth: 500, p: 2 }}>
              <Card sx={{ padding: 3, borderRadius: 4 }}>
                <Stack sx={{ p: { xs: 2, sm: 3 }, rowGap: 4 }}>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <img
                      style={{ height: 100 }}
                      loading="lazy"
                      src={Group116003x}
                      alt="Logo"
                    />
                  </Box>
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    columnGap={1}
                  >
                    <Typography
                      variant="h4"
                      fontWeight={"bold"}
                      textAlign="center"
                    >
                      Registration
                    </Typography>
                    <Typography fontSize="15px">
                      Already have an account?
                      <Link
                        href="/"
                        component="a"
                        sx={{ textDecoration: "none", ml: 1 }}
                      >
                        Login
                      </Link>
                    </Typography>
                  </Stack>
                  <TextField
                    placeholder="Name"
                    variant="outlined"
                    fullWidth
                    sx={{
                      "& input": {
                        boxShadow: "inset 0 0 7px rgba(0, 0, 0, 0.7)",
                        border: "none",
                        outline: "none",
                        fontSize: "14px",
                      },
                      color: "#b5b5b5",
                    }}
                    value={Name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <TextField
                    placeholder="Email"
                    variant="outlined"
                    fullWidth
                    sx={{
                      "& input": {
                        boxShadow: "inset 0 0 7px rgba(0, 0, 0, 0.7)",
                        border: "none",
                        outline: "none",
                        fontSize: "14px",
                      },
                      color: "#b5b5b5",
                    }}
                    value={Email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <TextField
                    placeholder="Contact Number"
                    variant="outlined"
                    fullWidth
                    sx={{
                      "& input": {
                        boxShadow: "inset 0 0 7px rgba(0, 0, 0, 0.7)",
                        border: "none",
                        outline: "none",
                        fontSize: "14px",
                      },
                      color: "#b5b5b5",
                    }}
                    value={phoneNumber}
                    onChange={(e) =>
                      setphoneNumber(e.target.value.slice(0, 11))
                    }
                  />
                  <TextField
                    multiline
                    minRows={4}
                    style={{ width: "100%" }}
                    placeholder="Address"
                    variant="outlined"
                    fullWidth
                    sx={{
                      "& input": {
                        boxShadow: "inset 0 0 7px rgba(0, 0, 0, 0.7)",
                        border: "none",
                        outline: "none",
                        fontSize: "14px",
                      },
                      color: "#b5b5b5",
                    }}
                    value={Address}
                    onChange={(e) => setAddress(e.target.value)}
                    disabled
                  />
                  <Button
                    variant="contained"
                    size="large"
                    fullWidth
                    sx={{
                      borderRadius: "5",
                      fontSize: "20",
                      fontWeight: "bold",
                    }}
                    onClick={Register}
                  >
                    Send Verification Code
                  </Button>

                  <Stack direction="row" justifyContent="center" spacing={2} alignItems="center"
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <img
                        style={{
                          width: "100%",
                          maxWidth: "150px",
                          height: "auto",
                        }}
                        loading="lazy"
                        src={en_badge_web_generic}
                        alt="Google Play Store"
                      />
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <img
                        style={{
                          width: "100%",
                          maxWidth: "130px",
                          height: "auto",
                        }}
                        loading="lazy"
                        src={appleplaystore}
                        alt="Apple App Store"
                      />
                    </Box>
                  </Stack>
                </Stack>
              </Card>
            </Box>
          </Grid>
        </Grid>
        <Stack
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            p: 1,
            backgroundImage:
              "linear-gradient(360deg, rgba(21, 51, 115, 1) 0%, rgba(104, 169, 217, 1) 100%)",
          }}
        >
          <Box>
            <Typography variant="body2" align="center" color="#fff">
              Powered by PSO
            </Typography>
          </Box>
        </Stack>
      </Box>

      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        contentLabel="Location Modal"
      >

        <div className="main_map_div">
          {isLoaded ? (
            <div className="inner_map_div">

              <div className="main_map_search_input_and_button_div">
                <Autocomplete
                  onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
                  onPlaceChanged={handlePlaceChanged}
                >
                  <input
                    className='places-input'
                    type='text'
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                  />
                </Autocomplete>
              </div>

              <GoogleMap
                // className="map"
                mapContainerStyle={containerStyle}
                center={coords}
                zoom={10}
                onLoad={onLoad}
                onUnmount={onUnmount}
              >
                <Marker
                  position={coords}
                  draggable={true}
                  onDragEnd={(e) =>
                    setCoords({ lat: e.latLng.lat(), lng: e.latLng.lng() })
                  }
                />
              </GoogleMap>
            </div>
          ) : (
            <h1>Loading...!</h1>
          )}
        </div>
      </Modal>
    </>
  );
};
