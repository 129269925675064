import React, { useState, useEffect } from 'react';
import './Dashboard.css';
import user_img from "../../../assets/user_img.png";
import cylinder_img from "../../../assets/Group 11613@3x.png";
import banner_img from "../../../assets/banner-img.png";
import yourorder from "../../../assets/your-order.png";
import Inhousecylinder from "../../../assets/cylinder.png";
import UnsubscribeCylinders from "../../../assets/return-cylinder.png";
import Nextorders from "../../../assets/calendar.png";
import { SlWallet } from "react-icons/sl";
import Slider from "react-slick";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Your_Order from './Your-Order/Your-Order';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { GetCustomerDetails } from '../../../redux/store/action/auth-action/auth-action';
import Next_orders from './Next-orders/Next-orders';
import In_house_cylinder from './In-house-cylinder/In-house-cylinder';
import Unsubscribe_Cylinders from './Unsubscribe-Cylinders/Unsubscribe-Cylinders';
import Modal from 'react-modal';
import { GetCustomerWallet } from '../../../redux/store/action/unauth-action/wallet-action/wallet-action';
import {
    GetCurrentPricesRegionBased,
    GetCustomerOrder,
    GetCustomerScheduledOrder,
    GetItemByID,
    GetItemList,
    GetPaymentMethodsListAll
} from '../../../redux/store/action/unauth-action/order-action/order-action';
import { ImCross } from "react-icons/im";
import { GetListAllCylinderStatus } from '../../../redux/store/action/unauth-action/dashboard-action/dashboard-action';


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

const Dashboard = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const { userId, authuserdetail } = useSelector(({ authStates }) => authStates);
    const { walletList } = useSelector(({ walletStates }) => walletStates);
    const { currentprices, orderList, scheduledorderList } = useSelector(({ orderStates }) => orderStates);
    const { selectaddress } = useSelector(({ addressStates }) => addressStates);
    const { AllCylinderStatusList } = useSelector(({ dashboardStates }) => dashboardStates);


    // Modal Function Start Here
    const [modalIsOpen, setIsOpen] = useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }


    const resHandler = (data) => {
        if (data) { }
    }

    useEffect(() => {
        dispatch(GetCustomerDetails(userId))
        dispatch(GetCurrentPricesRegionBased(resHandler))
        dispatch(GetPaymentMethodsListAll(resHandler))
        dispatch(GetItemByID(resHandler))
        dispatch(GetItemList(resHandler))
    }, [])

    useEffect(() => {
        if (authuserdetail?.customer?.customerId) {
            dispatch(GetListAllCylinderStatus(authuserdetail?.customer?.customerId, resHandler))
            dispatch(GetCustomerWallet(authuserdetail?.customer?.customerId, resHandler));
            dispatch(GetCustomerScheduledOrder(authuserdetail?.customer?.customerId, resHandler));
            dispatch(GetCustomerOrder(authuserdetail?.customer?.customerId, resHandler));
        }
    }, [authuserdetail?.customer?.customerId]);

    useEffect(() => {
        if (selectaddress !== null) {
            if (!selectaddress || !currentprices || currentprices.length === 0) {
                setIsOpen(false);
            } else {
                const foundCity = currentprices.some(price => price.city === selectaddress.city);
                setIsOpen(!foundCity);
            }
        }
    }, [selectaddress, currentprices]);

    const YourOrderFilterData = orderList?.orders?.filter(row => row?.status !== 'Delivered')
    const inactiveOrders = scheduledorderList?.filter(order => !order?.isScheduleActive);
    const inactivetrueOrders = scheduledorderList?.filter(order => order?.isScheduleActive === true);


    return (
        <>
            <div className='main_dashboard_div'>

                <div className='inner_dashboard_header_div'>

                    <div className='main_dashboard_header_user_profile_div'>
                        <div className='inner_dashboard_header_user_img_div'>
                            <img src={user_img} alt="" />
                        </div>
                        <div className='inner_dashboard_header_user_details_div'>
                            <div className='inner_dashboard_header_user_details_div1'>Hello!</div>
                            <div className='inner_dashboard_header_user_details_div2'>{authuserdetail?.customer?.name}</div>
                            <div className='inner_dashboard_header_user_details_div3'><SlWallet /> Rs. {walletList?.wallet?.balance}</div>
                        </div>
                    </div>

                    <div className='dashboard_header_order_now_div'>
                        <Link to='/order-now'>
                            <div>Order Now</div>
                            <img src={cylinder_img} alt="" />
                        </Link>
                    </div>

                </div>

                <div className='inner_dashboard_slider_div'>
                    <Slider {...settings}>
                        <div className='inner_banner_image_div'>
                            <img src={banner_img} alt="" />
                        </div>
                    </Slider>
                </div>

                <div className='inner_dashboard_cards_tabs_div'>
                    <Tabs>
                        <TabList>
                            <Tab>
                                <div className='main_dashboard_card_div'>
                                    <div className='main_dashboard_card_inner_div1'>
                                        <div className='main_dashboard_card_inner_div1_heading'>Your Order</div>
                                        <div className='main_dashboard_card_inner_div1_text'>{YourOrderFilterData?.length}</div>
                                    </div>
                                    <div className='main_dashboard_card_inner_div2'>
                                        <img src={yourorder} alt="" />
                                    </div>
                                </div>
                            </Tab>
                            <Tab>
                                <div className='main_dashboard_card_div'>
                                    <div className='main_dashboard_card_inner_div1'>
                                        <div className='main_dashboard_card_inner_div1_heading'>In house cylinder</div>
                                        <div className='main_dashboard_card_inner_div1_text'>{AllCylinderStatusList?.length}</div>
                                    </div>
                                    <div className='main_dashboard_card_inner_div2'>
                                        <img src={Inhousecylinder} alt="" />
                                    </div>
                                </div>
                            </Tab>
                            <Tab>
                                <div className='main_dashboard_card_div'>
                                    <div className='main_dashboard_card_inner_div1'>
                                        <div className='main_dashboard_card_inner_div1_heading'>Unsubscribe Cylinders</div>
                                        <div className='main_dashboard_card_inner_div1_text'>{inactiveOrders?.length}</div>
                                    </div>
                                    <div className='main_dashboard_card_inner_div2'>
                                        <img src={UnsubscribeCylinders} alt="" style={{ width: "45%" }} />
                                    </div>
                                </div>
                            </Tab>
                            <Tab>
                                <div className='main_dashboard_card_div'>
                                    <div className='main_dashboard_card_inner_div1'>
                                        <div className='main_dashboard_card_inner_div1_heading'>Next orders</div>
                                        <div className='main_dashboard_card_inner_div1_text'>{inactivetrueOrders?.length}</div>
                                    </div>
                                    <div className='main_dashboard_card_inner_div2'>
                                        <img src={Nextorders} alt="" />
                                    </div>
                                </div>
                            </Tab>
                        </TabList>

                        <TabPanel>
                            <Your_Order />
                        </TabPanel>
                        <TabPanel>
                            <In_house_cylinder />
                        </TabPanel>
                        <TabPanel>
                            <Unsubscribe_Cylinders />
                        </TabPanel>
                        <TabPanel>
                            <Next_orders />
                        </TabPanel>
                    </Tabs>
                </div>

            </div>

            <Modal
                isOpen={modalIsOpen}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className='main_dashboard_modal_div'>
                    <div className='inner_dashboard_modal_icon_div'>
                        <ImCross />
                    </div>
                    <div className='inner_dashboard_modal_text_div'>
                        Oops! Currently Our Servies <br /> are not working in Your Area
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default Dashboard;