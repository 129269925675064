import {
  Avatar,
  Box,
  Button,
  Card,
  CardMedia,
  FormControl,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import background_img from "../../../../src/assets/background_img.png";
import user__img from "../../../../src/assets/user_img.png";
import { Link, useNavigate } from "react-router-dom";
import { FormLabel, IconButton } from "@mui/joy";
import { useEffect, useState } from "react";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { useDispatch, useSelector } from "react-redux";
import { UpdateUserImage, UpdateUserInformation } from "../../../redux/store/action/unauth-action/myprofil-action/myprofil-action";
import { GetCustomerDetails } from "../../../redux/store/action/auth-action/auth-action";
import { toast } from "react-toastify";

export const MyProfile = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userId, authuserdetail } = useSelector(({ authStates }) => authStates);

  // console.log("authuserdetail");
  // console.log(authuserdetail);

  const [imageSrc, setImageSrc] = useState(
    authuserdetail?.base64Image || user__img
  );

  const [name, setname] = useState('');
  const [email, setemail] = useState('');
  const [date_of_birth, setdate_of_birth] = useState('');
  const [cnic, setcnic] = useState('');
  const [cnic_expiry, setcnic_expiry] = useState('');


  useEffect(() => {
    if (authuserdetail?.customer) {
      setname(authuserdetail?.customer?.name)
      setemail(authuserdetail?.customer?.email)
      setdate_of_birth(authuserdetail?.customer?.dateOfBirth)
      setcnic(authuserdetail?.customer?.cnic)
      setcnic_expiry(authuserdetail?.customer?.cnicExpiryDate)
    }
  }, [authuserdetail?.customer]);

  // useEffect(() => {
  //   const savedImageSrc = localStorage.getItem("profileImageSrc");
  //   if (savedImageSrc) {
  //     setImageSrc(savedImageSrc);
  //   }
  // }, []);

  const resHandler = (data) => {
    if (data) {
      dispatch(GetCustomerDetails(userId))
    }
  }


  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const editedImageSrc = e.target.result;
        setImageSrc(editedImageSrc);

        let formData = {
          userId: authuserdetail?.userId,
          base64Image: editedImageSrc
        }

        dispatch(UpdateUserImage(formData, resHandler))

        // localStorage.setItem("profileImageSrc", editedImageSrc);
      };
      reader.readAsDataURL(file);
    }
  };


  const UpdatedProfile = () => {

    if (name === '') {
      toast.error("Please enter a Name!");
    } else if (email === '') {
      toast.error("Please enter a Email!");
    } else if (date_of_birth === '') {
      toast.error("Please enter a Date Of Birth!");
    } else if (cnic === '') {
      toast.error("Please enter a CNIC!");
    } else if (cnic_expiry === '') {
      toast.error("Please enter a CNIC Expiry!");
    } else {

      let formData = {
        "customerId": authuserdetail?.customer?.customerId,
        "dateOfBirth": date_of_birth,
        "defaultLoc": authuserdetail?.customer?.defaultLoc,
        "cnicExpiryDate": cnic_expiry,
        "cnic": cnic,
        "name": name,
        "email": email
      }

      dispatch(UpdateUserInformation(formData, resHandler))

    }


  }


  return (
    <>
      <div className="main_order_list_div">

        <div className="main_order_list_tabs_section_div">
          <div className="order_list_tab_active"><Link to="My-Profile">My Profile</Link></div>
          <div><Link to="/order-list">Order list</Link></div>
          <div><Link to="/manage-address">Manage Address</Link></div>
          <div><Link to="/Manage-Cards">Manage Cards</Link></div>
          <div><Link to="/wallet">Wallet</Link></div>
        </div>

        <Box
          item
          fixed
          sx={{
            backgroundImage: `url('${background_img}')`,
            backgroundSize: "cover",
            display: "flex",
            alignItems: "center",
            backgroundAttachment: "fixed",
          }}
        >
          <Stack direction="column" sx={{ flexGrow: 15 }}>
            <Box>
              <Card
                sx={{
                  borderRadius: 4,
                  boxShadow: 2,
                }}
              >
                <Grid sx={{ p: 9, rowGap: 5 }}>
                  <Stack direction="row" columnGap={10}>
                    <Stack rowGap={5}>
                      <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        columnGap={1}
                      >
                        <Avatar />
                        <Typography sx={{ fontSize: "30px", color: "#1591c7" }}>
                          My Profile
                        </Typography>
                      </Stack>
                      <Stack rowGap={5}>
                        <Card
                          sx={{
                            borderRadius: 2,
                            boxShadow: 2,
                            padding: 4,
                            position: "relative",
                            display: "inline-block",
                          }}
                        >
                          <div
                            style={{
                              position: "relative",
                              display: "inline-block",
                            }}
                          >
                            <CardMedia
                              component="img"
                              image={imageSrc}
                              alt={user__img}
                              sx={{
                                borderRadius: "50%",
                                width: "250px",
                                height: "250px",
                              }}
                            />
                            <input
                              accept="image/*"
                              type="file"
                              style={{ display: "none" }}
                              id="profile-image-upload"
                              onChange={handleImageChange}
                            />
                            <label htmlFor="profile-image-upload">
                              <IconButton
                                component="span"
                                sx={{
                                  position: "absolute",
                                  top: "70%",
                                  right: "5%",
                                  transform: "translateY(-50%)",
                                  backgroundColor: "white",
                                  borderRadius: "50px",
                                  border: "1px solid #ccc",
                                  height: "60px",
                                  width: "60px",
                                  "&:hover": {
                                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                                  },
                                }}
                              >
                                <PhotoCameraIcon
                                  sx={{ height: "40px", width: "40px" }}
                                />
                              </IconButton>
                            </label>
                          </div>
                          <Typography
                            sx={{
                              fontSize: 24,
                              textAlign: "center",
                              marginTop: 2,
                            }}
                          >
                            Shehroz Jawed
                          </Typography>
                        </Card>
                        <Button variant="contained" onClick={UpdatedProfile}>Updated Profile</Button>
                      </Stack>
                    </Stack>
                    <Stack rowGap={5} sx={{ width: "100%" }}>
                      <FormControl>
                        <FormLabel
                          sx={{
                            fontSize: "1.2rem",
                            color: "black",
                            marginBottom: "6px",
                          }}
                        >
                          Name
                        </FormLabel>
                        <TextField
                          size="lg"
                          variant="standard"
                          InputProps={{
                            style: { color: "#05597b", fontSize: "20px" },
                          }}
                          value={name}
                          onChange={(e) => setname(e.target.value)}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel
                          sx={{
                            fontSize: "1.2rem",
                            color: "black",
                            marginBottom: "6px",
                          }}
                        >
                          Email
                        </FormLabel>
                        <TextField
                          size="lg"
                          variant="standard"
                          InputProps={{
                            style: { color: "#05597b", fontSize: "20px" },
                          }}
                          value={email}
                          onChange={(e) => setemail(e.target.value)}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel
                          sx={{
                            fontSize: "1.2rem",
                            color: "black",
                            marginBottom: "6px",
                          }}
                        >
                          Date Of Birth
                        </FormLabel>
                        <TextField
                          size="lg"
                          variant="standard"
                          type="date"
                          InputProps={{
                            style: { color: "#05597b", fontSize: "20px" },
                          }}
                          value={date_of_birth}
                          onChange={(e) => setdate_of_birth(e.target.value)}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel
                          sx={{
                            fontSize: "1.2rem",
                            color: "black",
                            marginBottom: "6px",
                          }}
                        >
                          CNIC
                        </FormLabel>
                        <TextField
                          size="lg"
                          variant="standard"
                          InputProps={{
                            style: { color: "#05597b", fontSize: "20px" },
                          }}
                          value={cnic}
                          onChange={(e) => setcnic(e.target.value.slice(0, 13))}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel
                          sx={{
                            fontSize: "1.2rem",
                            color: "black",
                            marginBottom: "6px",
                          }}
                        >
                          CNIC Expiry
                        </FormLabel>
                        <TextField
                          size="lg"
                          variant="standard"
                          type="date"
                          InputProps={{
                            style: { color: "#05597b", fontSize: "20px" },
                          }}
                          value={cnic_expiry}
                          onChange={(e) => setcnic_expiry(e.target.value)}
                        />
                      </FormControl>
                    </Stack>
                  </Stack>
                </Grid>
              </Card>
            </Box>
          </Stack>
        </Box>
      </div>
    </>
  );
};
