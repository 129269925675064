// import axios from "axios"
import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import instance from "../../../../../libraries-configuration/axios-config/axios-config";
import apiCallMethods from '../../../../../libraries-configuration/api-methods/api-methods';
import { SELECTADDRES } from '../../../reducer/unauth-reducer/address-reducer/address-reducer';



let endPoints = {
    getcustomerwallet: '/VirtualWallet/WalletGetById/',
    addaddress: '/Customer/AddNewCustomerAddress',
    updateaddress: '/Customer/UpdateCustomerAddress',
};

const SelectAddress = (selectaddress) => {
    return (dispatch) => {
        dispatch({
            type: SELECTADDRES,
            payload: selectaddress
        });
    };
};

const AddAddress = (formData, resHandler) => {

    return async (dispatch) => {
        try {
            let response = await instance({
                method: apiCallMethods.POST,
                url: endPoints.addaddress,
                data: formData
            });

            console.log('Add Address data:', response)
            let { status, data } = response;

            if (status === 200) {
                if (data.statusCode === 200) {

                    // dispatch({
                    //     type: USERINFORMATION,
                    //     payload: data?.data
                    // })
                    resHandler(response)
                    toast.success(data?.message)

                } else {
                    toast.error(response?.data?.message)
                }
            }

        } catch (error) {
            resHandler(error?.response?.data)
            console.log(error);
            toast.error(error?.response?.data?.message); // Corrected toast method
        }
    };
};

const UpdateAddress = (formData, resHandler) => {

    return async (dispatch) => {
        try {
            let response = await instance({
                method: apiCallMethods.POST,
                url: endPoints.updateaddress,
                data: formData
            });

            console.log('Update Address data:', response)
            let { status, data } = response;

            if (status === 200) {
                if (data.statusCode === 200) {

                    // dispatch({
                    //     type: USERINFORMATION,
                    //     payload: data?.data
                    // })
                    resHandler(response)
                    toast.success(data?.message)

                } else {
                    toast.error(response?.data?.message)
                }
            }

        } catch (error) {
            resHandler(error?.response?.data)
            console.log(error);
            toast.error(error?.response?.data?.message); // Corrected toast method
        }
    };
};




export {
    SelectAddress,
    AddAddress,
    UpdateAddress,
};

<ToastContainer />; // Place the ToastContainer outside of functions
