import React, { useState } from "react";
import {
  Grid,
  Box,
  Card,
  TextField,
  Typography,
  Stack,
  Button,
  Link,
} from "@mui/material";
import Group116683x from "../../../assets/Group 11668@3x.png";
import Group116672x from "../../../assets/Group 11667@2x.png";
import Group116003x from "../../../assets/Group 11600@3x.png";
import appleplaystore from "../../../assets/AAsS@3x.png";
import en_badge_web_generic from "../../../assets/en_badge_web_generic@3x.png";
import { useDispatch } from "react-redux";
import { loginUser } from "../../../redux/store/action/auth-action/auth-action";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

function SignIn() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [phoneNumber, setPhoneNumber] = useState(null);

  const resHandler = (data) => {
    if (data) {
      localStorage.setItem("phoneNumber", phoneNumber);
      navigate("/otp");
    }
  };

  const login = () => {
    if (!phoneNumber) {
      toast.error("Please enter a Phone Number!");
    } else {
      let formData = {
        phoneNumber: phoneNumber,
      };

      dispatch(loginUser(formData, resHandler));
    }
  };

  return (
    <Box
      sx={{
        backgroundImage: `url('${Group116683x}')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100dvh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: { xs: 2, sm: 4 },
      }}
    >
      <Grid container spacing={2} justifyContent="center">
        <Grid
          item
          xs={6}
          md={6}
          lg={5}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mb: { xs: 4, md: 0 },
          }}
        >
          <img
            style={{ maxHeight: { xs: 300, md: 624 }, width: "100%", objectFit: "contain" }}
            loading="lazy"
            src={Group116672x}
            alt="Group11667"
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          lg={7}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box sx={{ maxWidth: 500, width: "100%", p: 2 }}>
            <Card sx={{ padding: 3, borderRadius: 4 }}>
              <Stack sx={{ p: { xs: 2, sm: 3 }, rowGap: 4 }}>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <img
                    style={{ height: 100 }}
                    loading="lazy"
                    src={Group116003x}
                    alt="Group11600"
                  />
                </Box>
                <Typography variant="h4" fontWeight="bold" textAlign="center">
                  Login
                </Typography>
                <TextField
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value.slice(0, 11))}
                  placeholder="Registered Contact Number"
                  variant="outlined"
                  fullWidth
                  type="number"
                  sx={{
                    "& input": {
                      boxShadow: "inset 0 0 7px rgba(0, 0, 0, 0.7)",
                      border: "none",
                      outline: "none",
                      fontSize: "14px",
                    },
                    color: "#b5b5b5",
                  }}
                />
                <Button
                  variant="contained"
                  size="large"
                  fullWidth
                  sx={{ borderRadius: 5, fontSize: 20, fontWeight: "bold" }}
                  onClick={login}
                >
                  Generate OTP
                </Button>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  columnGap={1}
                >
                  <Typography fontSize="15px">
                    Don't have an account?
                    <Link
                      href="/SignUp"
                      component="a"
                      sx={{ textDecoration: "none" }}
                    >
                      {" "}
                      Create Account
                    </Link>
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <img
                      style={{
                        width: "100%",
                        maxWidth: "150px",
                        height: "auto",
                      }}
                      loading="lazy"
                      src={en_badge_web_generic}
                      alt="Google Play Store"
                    />
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <img
                      style={{
                        width: "100%",
                        maxWidth: "130px",
                        height: "auto",
                      }}
                      loading="lazy"
                      src={appleplaystore}
                      alt="Apple Play Store"
                    />
                  </Box>
                </Stack>
              </Stack>
            </Card>
          </Box>
        </Grid>
      </Grid>
      <Stack
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          width: "100%",
          p: 1,
          backgroundImage:
            "linear-gradient(360deg, rgba(21, 51, 115, 1) 0%, rgba(104, 169, 217, 1) 100%)",
        }}
      >
        <Box>
          <Typography variant="body2" align="center" color="#fff">
            Powered by PSO
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
}

export default SignIn;
