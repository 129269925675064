import React, { useState } from "react";
import {
  Grid,
  Box,
  Card,
  Typography,
  Stack,
  Button,
  Link,
} from "@mui/material";
import Group116683x from "../../../assets/Group 11668@3x.png";
import Group116672x from "../../../assets/Group 11667@2x.png";
import Group116003x from "../../../assets/Group 11600@3x.png";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useDispatch } from "react-redux";
import { UserOtp, loginUser } from "../../../redux/store/action/auth-action/auth-action";
import { toast } from "react-toastify";



export const OtpScreen = (props) => {

  const dispatch = useDispatch();

  const phoneNumber = localStorage.getItem("phoneNumber");
  const firebasetoken = localStorage.getItem("firebasetoken");


  const [Otp, setOtp] = useState('')


  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  const resHandler = (data) => {
    if (data) { }
  }

  const otp = () => {

    if (Otp === '') {
      toast.error("Please enter a OTP!")
    } else {

      let formData = {
        "otpCode": String(Otp),
        "phoneNumber": String(phoneNumber),
        "fcmToken": firebasetoken
      }
      // console.log(formData);
      dispatch(UserOtp(formData, resHandler))

    }

  }


  const resendotp = () => {

    const phoneNumber = localStorage.getItem("phoneNumber");

    let formData = {
      phoneNumber: phoneNumber
    }

    dispatch(loginUser(formData, resHandler))

  }

  return (
    <Box
      sx={{
        backgroundImage: `url('${Group116683x}')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100dvh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: { xs: 2, sm: 4 },
      }}
    >
      <Grid container spacing={2} justifyContent="center">
        <Grid
          item
          xs={6}
          md={6}
          lg={5}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mb: { xs: 4, md: 0 },
          }}
        >
          <img
            style={{ maxHeight: { xs: 300, md: 624 }, width: "100%", objectFit: "contain" }}
            loading="lazy"
            src={Group116672x}
            alt={Group116672x}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          lg={7}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box sx={{ maxWidth: 500, width: "100%", p: 2 }}>
            <Card sx={{ padding: 3, borderRadius: 4 }}>
              <Stack sx={{ p: 3, rowGap: 4 }}>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <img
                    style={{ height: 100 }}
                    loading="lazy"
                    src={Group116003x}
                    alt={Group116003x}
                  />
                </Box>

                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  columnGap={1}
                >
                  <Typography
                    variant="h4"
                    fontWeight={"bold"}
                    textAlign="center"
                  >
                    OTP Verification
                  </Typography>
                  <Typography fontSize="12px" color="#8c8e92">
                    Enter the OTP sent to {phoneNumber}
                  </Typography>
                </Stack>
                <MuiOtpInput
                  value={Otp}
                  onChange={handleChange}
                  length={4}
                  sx={{ borderRadius: "20px" }}
                />
                <Button
                  variant="contained"
                  size="large"
                  fullWidth
                  sx={{ borderRadius: "5", fontSize: "20", fontWeight: "bold" }}
                  onClick={otp}
                >
                  Verify
                </Button>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  columnGap={1}
                >
                  <Typography fontSize="15px">
                    Didn't recieve OTP?
                    <Link component="a" sx={{ textDecoration: "none" }} onClick={resendotp}>
                      Resend
                    </Link>
                  </Typography>
                </Stack>
              </Stack>
            </Card>
          </Box>
        </Grid>
      </Grid>
      <Stack
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          width: "100%",
          p: 1,
          backgroundImage:
            "linear-gradient(360deg, rgba(21, 51, 115, 1) 0%, rgba(104, 169, 217, 1) 100%)",
        }}
      >
        <Box>
          <Typography variant="body2" align="center" color="#fff">
            Powered by PSO
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};
