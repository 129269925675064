import React, { useEffect, useMemo, useState } from 'react';
import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';
import cylinder_img from "../../../../assets/Group 11613@3x.png";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { CancelRecurringOrder, CreateRecurringOrder, GetCustomerScheduledOrder } from '../../../../redux/store/action/unauth-action/order-action/order-action';
import {
    Box,
    Button,
    Card,
    Divider,
    IconButton,
    Modal,
    Stack,
    Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { FaCalendarAlt } from "react-icons/fa";


function Scheduled() {


    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { authuserdetail } = useSelector(({ authStates }) => authStates);
    const { scheduledorderList, recuringorderconfirmedlist } = useSelector(({ orderStates }) => orderStates);

    const [open, setOpen] = useState(false);
    const [currentDate, setCurrentDate] = useState('');
    const [RowData, setRowData] = useState(null);

    useEffect(() => {
        const date = new Date();
        const formattedDate = formatDate(date);
        setCurrentDate(formattedDate);
    }, []);

    const formatDate = (date) => {
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();
        return `${month}/${day}/${year}`;
    };

    const handleDateChange = (e) => {
        const date = new Date(e.target.value);
        const formattedDate = formatDate(date);
        setCurrentDate(formattedDate);
    };

    const handleOpen = (row) => {
        setRowData(row.original);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const resHandler = (data) => {
        if (data) { }
    };

    const RecurringresHandler = (data) => {
        if (data?.data?.statusCode === 200 || (data?.data?.succeeded === true)) {
            // console.log("i am here");
            dispatch(GetCustomerScheduledOrder(authuserdetail?.customer?.customerId, resHandler));
            setOpen(false);
        }
    };

    const ConformRecurringOrderFunction = (row) => {
        // console.log("Conform Recurring Order Function");
        const RowData = row.original || row;
        setRowData(RowData);
        // console.log(RowData);
        let formData = {
            "schedulerId": RowData?.recurringSchedulerId,
            "total": RowData?.total,
            "amount": RowData?.amount,
            "paymentMethodId": RowData?.paymentMethodId,
            "newRequiredDate": currentDate
        };

        dispatch(CreateRecurringOrder(formData, RecurringresHandler));
    };

    const CancelRecurringOrderFunction = (row) => {
        // console.log("Cancel Recurring Order Function");
        const RowData = row.original || row;
        setRowData(RowData);
        // console.log(RowData);
        let formData = {
            "scheduleId": RowData?.recurringSchedulerId,
            "customerId": RowData?.customerId,
            "isActive": false
        };

        dispatch(CancelRecurringOrder(formData, RecurringresHandler));
    };

    const data = useMemo(() => scheduledorderList || [], [scheduledorderList]);

    const columns = useMemo(
        () => [
            {
                accessorKey: 'orderId',
                header: '',
                Cell: ({ row }) => (
                    <div
                        className="order_list_card_inner_div"
                        style={{
                            backgroundColor: "white",
                            borderRadius: "100px",
                            boxShadow: "0px 0px 5px lightgray",
                            padding: 5,
                        }}
                    >
                        <img src={cylinder_img} alt="" />
                    </div>
                ),
            },
            {
                accessorKey: 's',
                header: 'Item Name',
            },
            {
                accessorKey: 'requiredDate',
                header: 'Date',
            },
            {
                accessorKey: 'status',
                header: 'Status',
            },
            {
                accessorKey: 'qty',
                header: 'Quantity',
            },
            {
                accessorKey: 'orderId',
                header: '',
                Cell: ({ row }) => {
                    const isMatch = recuringorderconfirmedlist?.some(confirmed =>
                        confirmed?.RecurringSchedulerId === row?.original?.recurringSchedulerId &&
                        confirmed?.CustomerId === row?.original?.customerId &&
                        confirmed?.OrderId === row?.original?.orderId &&
                        confirmed?.NextScheduleDate === row?.original?.nextScheduleDate &&
                        confirmed?.IsScheduleActive === row?.original?.isScheduleActive
                    );

                    return (
                        <div className='main_scheduled_div'>
                            {isMatch && row?.original?.isScheduleActive && (
                                <>
                                    <button style={{ backgroundColor: "#03a07b" }} onClick={() => ConformRecurringOrderFunction(row)}>Confirm</button>
                                    <button style={{ backgroundColor: "#195389" }} onClick={() => handleOpen(row)}>Edit</button>
                                </>
                            )}
                        </div>
                    );
                },
            },
        ],
        [],
    );

    const table = useMaterialReactTable({
        columns,
        data
    });

    const getCurrentDate = () => {
        const today = new Date();
        const yyyy = today.getFullYear();
        const mm = String(today.getMonth() + 1).padStart(2, '0');
        const dd = String(today.getDate()).padStart(2, '0');
        return `${yyyy}-${mm}-${dd}`;
    };

    return (
        <>

            {/* Order Confirmation Modal Start Here */}
            <Modal
                open={open}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Card
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 800,
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        borderRadius: 10,
                    }}
                >
                    <Typography
                        id="modal-title"
                        variant="h6"
                        sx={{
                            textAlign: "center",
                            color: "#1591c7",
                            fontWeight: "bold",
                            fontSize: "30px",
                            marginBottom: "60px",
                        }}
                    ></Typography>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: "#fff",
                            backgroundColor: "#dc0000",
                        }}
                    >
                        <CloseIcon />
                    </IconButton>

                    <Typography
                        sx={{
                            color: "#1591c7",
                            fontSize: "1.8rem",
                            fontWeight: "bold",
                            textAlign: "center",
                        }}
                    >
                        Your order is scheduled to be delivered on {currentDate}
                    </Typography>
                    <Stack direction="row" justifyContent="center" spacing={7} p={4}>
                        <Button
                            variant="contained"
                            sx={{
                                width: "200px",
                                height: "50px",
                                borderRadius: "20px",
                                backgroundColor: "#195389",
                            }}
                            onClick={() => ConformRecurringOrderFunction(RowData)}
                        >
                            Confirm
                        </Button>
                        <Button
                            variant="contained"
                            sx={{
                                width: "200px",
                                height: "50px",
                                borderRadius: "20px",
                                backgroundColor: "#195389",
                                position: "relative",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: "pointer",
                            }}
                            component="label"
                        >
                            Reschedule &nbsp; <FaCalendarAlt />
                            <input
                                id='reschedule'
                                name='reschedule'
                                type="date"
                                value={currentDate.split('/').reverse().join('-')}
                                onChange={handleDateChange}
                                min={getCurrentDate()}
                                style={{
                                    position: "absolute",
                                    opacity: 0,
                                    cursor: "pointer",
                                }}
                            />
                        </Button>
                    </Stack>
                    <Stack direction="column" justifyContent="center" alignItems="center" spacing={2} p={2}>
                        <Typography
                            sx={{
                                color: "black",
                                fontSize: "20px",
                                fontWeight: "bold",
                                textAlign: "center",
                            }}
                        >
                            Don't want to continue with this subscription?
                        </Typography>
                        <Typography
                            sx={{
                                color: "red",
                                fontSize: "20px",
                                fontWeight: "bold",
                                textAlign: "center",
                            }}
                            onClick={() => CancelRecurringOrderFunction(RowData)}
                        >
                            Cancel Subscription
                        </Typography>
                    </Stack>
                </Card>
            </Modal>
            {/* Order Confirmation Modal End Here */}

            <MaterialReactTable table={table} />
        </>
    )
}

export default Scheduled
