import React, { useEffect, useState } from "react";
import "./Order-Now.css";
import refill_cylinder_img from "../../../assets/refill-cylinder.png";
import new_cylinder_img from "../../../assets/new-cylinder.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Refill from "./Refill/Refill";
import New_cylinder from "./New-cylinder/New-cylinder";
import { useSelector } from "react-redux";

function Order_Now() {

  const { scheduledorderList } = useSelector(({ orderStates }) => orderStates);

  return (
    <>
      <div className="main_order_detail_div">
        <Tabs>
          <TabList>
            {scheduledorderList.length === 0 ? null :
              <Tab>
                <div className="order_detail_tab_div">
                  <div>
                    <img src={refill_cylinder_img} alt="" />
                  </div>
                  <div>Refill</div>
                </div>
              </Tab>
            }

            <Tab>
              <div className="order_detail_tab_div">
                <div>
                  <img src={new_cylinder_img} alt="" />
                </div>
                <div>New cylinder</div>
              </div>
            </Tab>
          </TabList>
          {scheduledorderList.length === 0 ? null :
            <TabPanel>
              <Refill />
            </TabPanel>
          }

          <TabPanel>
            <New_cylinder />
          </TabPanel>
        </Tabs>
      </div>
    </>
  );
}

export default Order_Now;
