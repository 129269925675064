import React, { useEffect, useMemo, useState } from 'react';
import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';
import cylinder_img from "../../../../assets/Group 11613@3x.png";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GetCustomerOrder } from '../../../../redux/store/action/unauth-action/order-action/order-action';


function Past() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { authuserdetail } = useSelector(({ authStates }) => authStates);
    const { orderList } = useSelector(({ orderStates }) => orderStates);

    // const resHandler = (data) => {
    //     if (data) {
    //         console.log(data);
    //     }
    // }

    // useEffect(() => {
    //     dispatch(GetCustomerOrder(authuserdetail?.customer?.customerId, resHandler))
    // }, [dispatch, authuserdetail?.customer?.customerId]);


    const data = useMemo(() => orderList.orders ? orderList.orders.filter(row => row.status === 'Delivered') : [], [orderList.orders]);

    const columns = useMemo(
        () => [
            {
                accessorKey: 'orderId',
                header: '',
                Cell: ({ row }) => (
                    <div
                        className="order_list_card_inner_div"
                        style={{
                            backgroundColor: "white",
                            borderRadius: "100px",
                            boxShadow: "0px 0px 5px lightgray",
                            padding: 5,
                        }}
                    >
                        <img src={cylinder_img} alt="" />
                    </div>
                ),
            },
            {
                accessorKey: 's',
                header: 'Item Name',
            },
            {
                accessorKey: 'requiredDate',
                header: 'Date',
                Cell: ({ row }) => (
                    <p>{row.original?.currentScheduleStatus?.requiredDate}</p>
                ),
            },
            {
                accessorKey: 'status',
                header: 'Status',
                filter: (rows) => rows.filter(row => row.original.status !== 'Delivered')
            },
            {
                accessorKey: 'id',
                header: 'Quantity',
                Cell: ({ row }) => (
                    <p>{row.original?.orderDetail?.qty}</p>
                ),
            },
        ],
        [],
    );

    const table = useMaterialReactTable({
        columns,
        data
    });


    return (
        <>
            <MaterialReactTable table={table} />
        </>
    )
}


export default Past
