import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AuthRoutes from './AuthRoutes/AuthRoutes';
import UnAuthRoutes from './UnAuthRoutes/UnAuthRoutes';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { generateToken, messaging } from '../Firebase';
import { onMessage } from 'firebase/messaging';
import { RecuringOrderConfirmed } from '../redux/store/action/unauth-action/order-action/order-action';

const AppRoutes = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        generateToken();
        onMessage(messaging, (payload) => {
            console.log('NOTIF:', payload)
            toast.success(payload?.notification?.body)
            if (payload?.data) {
                dispatch(RecuringOrderConfirmed(payload.data))
            }
        })
    }, []);

    const { authuserdetail } = useSelector(({ authStates }) => authStates);

    return (
        <>
            <Router>
                {authuserdetail ? (
                    <UnAuthRoutes />
                ) : (
                    <AuthRoutes />
                )}
            </Router>
        </>
    )
}

export default AppRoutes