/***** Note: Axios Configuration File *****/

import axios from "axios";

const urls = {
    // hostedUrl: "http://connect.uzairbaig.com:5106/api"
    // hostedUrl: "https://connect.uzairbaig.com:51003/api"
    hostedUrl: "http://connect.uzairbaig.com:5107/api"
};

// Default config options...!
const defaultOptions = {
    baseURL: urls.hostedUrl,
    headers: {
        "Content-Type": "application/json",
    }
};

// Create instance...!
const instance = axios.create(defaultOptions);

// Function to refresh token
const refreshToken = async () => {

    try {

        const fetchToken = localStorage.getItem("AuthToken");
        const refreshToken = localStorage.getItem("RefreshToken");

        const response = await instance.post(`/Auth/RefreshToken?token=${fetchToken}&refreshToken=${refreshToken}`);
        const newToken = response.data?.data?.jwToken;
        localStorage.setItem("AuthToken", newToken);
        return newToken;

    } catch (error) {
        localStorage.clear();
        window.location.href = "/"
    }

};

// Set the AUTH token for any request...!
instance.interceptors.request.use((config) => {
    let fetchToken = localStorage.getItem("AuthToken");
    // let parseToken = JSON.parse(fetchToken);
    config.headers.Authorization = fetchToken ? `Bearer ${fetchToken}` : "";
    return config;
});

// Intercept the response to handle token expiration
instance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const newToken = await refreshToken();
            originalRequest.headers.Authorization = `Bearer ${newToken}`;
            return instance(originalRequest);
        }
        return Promise.reject(error);
    }
);

export default instance;