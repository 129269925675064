import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom/dist';
import { toast } from 'react-toastify';
import { FaMinus, FaPlus } from "react-icons/fa";
import { Divider } from '@mui/material';
import { ImLocation } from "react-icons/im";
import { FaArrowLeft } from "react-icons/fa";
import { GetCustomerOrder, GetCustomerScheduledOrder, ReturnSendRequest } from '../../../../redux/store/action/unauth-action/order-action/order-action';
import dayjs from 'dayjs';


const Unsubscribe_Cylinders = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { scheduledorderList } = useSelector(({ orderStates }) => orderStates);
    const { selectaddress } = useSelector(({ addressStates }) => addressStates);
    const { AllCylinderStatusList } = useSelector(({ dashboardStates }) => dashboardStates);
    const { authuserdetail } = useSelector(({ authStates }) => authStates);

    const [ReturnCylinder, setReturnCylinder] = useState(false)
    const [quantity, setQuantity] = useState(0);

    const inactiveOrders = scheduledorderList?.filter(order => !order?.isScheduleActive);
    // console.log(inactiveOrders);

    const unsubscribed_cylinder_function = (text) => {
        if (inactiveOrders?.length === 0) {
            toast.error("No Cylinder In Hand")
        } else {
            if (text.toLowerCase() === 'refill') {
                navigate('/order-now')
            } else {
                setReturnCylinder(true)
            }
        }
    }

    const GoBack = () => {
        setQuantity(0);
        setReturnCylinder(false)
    }

    const plusHandlerAndMinus = (value) => {
        const newQuantity = quantity + value;

        if (AllCylinderStatusList?.length === 0) {
            toast.error("No Cylinder In Hand");
        } else if (newQuantity < 0) {
            toast.error("Quantity cannot be negative");
        } else if (newQuantity > AllCylinderStatusList?.length) {
            toast.info(`You can only Return ${AllCylinderStatusList?.length} cylinders.`);
        } else {
            setQuantity(newQuantity);
        }
    };

    const OtherresHandler = (data) => {
        if (data) { }
    }

    const resHandler = (data) => {
        if (data?.data?.statusCode === 200 || data?.data?.succeeded === true) {
            dispatch(GetCustomerScheduledOrder(authuserdetail?.customer?.customerId, OtherresHandler));
            dispatch(GetCustomerOrder(authuserdetail?.customer?.customerId, OtherresHandler));
        }
    }

    const ReturnSendRequestFunction = () => {
        if (quantity === 0) {
            toast.error("Please Add Cylinder!");
        } else {
            let formData = {
                customerId: authuserdetail?.customer?.customerId,
                itemId: 1,
                appType: "Web",
                invoiceType: "NA",
                customerDetailAddressId: selectaddress?.id,
                qty: quantity,
                requiredDate: dayjs().format('MM-DD-YYYY'),
                securityDeposit: 0
            }
            // console.log(formData);
            dispatch(ReturnSendRequest(formData, resHandler))
        }

    }


    return (
        <>

            {ReturnCylinder ? (
                <div className='main_unsubscribe_cylinders_div'>
                    <div>
                        <div className='main_order_status_back_arrow_div'><FaArrowLeft onClick={GoBack} /></div>
                    </div>
                    <div className='main_heading_unsubscribe_cylinders_div'>Return Cylinder</div>
                    <div className='inner_unsubscribe_cylinders_div'>
                        <div className='heading_unsubscribe_cylinders_div'>Select the cylinders you want to return</div>
                        <div className='main_quantity_div'>
                            <div className='inner_quantity_heading_div'>Quantity</div>
                            <div className="main_add_quantity_div">
                                <div>
                                    <FaMinus onClick={() => plusHandlerAndMinus(-1)} />
                                </div>
                                <div>{quantity}</div>
                                <div>
                                    <FaPlus onClick={() => plusHandlerAndMinus(1)} />
                                </div>
                            </div>
                        </div>
                        <Divider variant="middle" flexItem />
                        <div className='main_show_selectaddress_div'>
                            <div className='inner_show_selectaddress_div1'>
                                <div style={{ color: "red" }}><ImLocation /></div>
                                <div>
                                    <div>Location for pickup</div>
                                    <div style={{ color: "#0A74B9" }}>{selectaddress?.address}</div>
                                </div>
                            </div>
                            <div className='inner_show_selectaddress_div2'><Link to='/manage-address'>Change the address</Link></div>
                        </div>
                        <Divider variant="middle" flexItem />
                        <div className='main_send_request_div'>
                            <button onClick={ReturnSendRequestFunction}>Send Request</button>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='main_unsubscribe_cylinders_div'>
                    <div className='main_heading_unsubscribe_cylinders_div'>Unsubscribed Cylinder</div>
                    <div className='inner_unsubscribe_cylinders_div'>
                        <div className='heading_unsubscribe_cylinders_div'>You have {inactiveOrders?.length} unsubscribed cylinder</div>
                        <div className='text_unsubscribe_cylinders_div'>Create another refill subscription for these cylinders</div>
                        <div className='button_holder_unsubscribe_cylinders_div'>
                            <button style={{ backgroundColor: "#2d4c9c" }} onClick={() => unsubscribed_cylinder_function("refill")}>Refill</button>
                            <div>OR</div>
                            <button style={{ backgroundColor: "#c10000" }} onClick={() => unsubscribed_cylinder_function("return")}>Return</button>
                        </div>
                    </div>
                </div>
            )}

        </>
    )
}

export default Unsubscribe_Cylinders
