import React, { useEffect, useState } from "react";
import cylinder_img from "../../../../assets/Group 11613@3x.png";
import { FaMinus, FaPlus } from "react-icons/fa";
import {
    Card,
    CardContent,
    Divider,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Button,
    Box,
    IconButton,
    Modal,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { CreateOrder } from "../../../../redux/store/action/unauth-action/order-action/order-action";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import { MuiOtpInput } from "mui-one-time-password-input";
import checkedcylinder from "../../../../../src/assets/checkedcylinder.png";


function New_cylinder() {


    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { authuserdetail } = useSelector(({ authStates }) => authStates);
    const { selectaddress } = useSelector(({ addressStates }) => addressStates);
    const { paymentmethodslist, currentprices, createorderdetail, itemlist } = useSelector(({ orderStates }) => orderStates);

    const [quantity, setQuantity] = useState(0);
    const [open, setOpen] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState("");
    const [CurrectCityObject, setCurrectCityObject] = useState(null);

    // Pricing State Start Here
    const [Delivery_Date, setDelivery_Date] = useState(null);
    const [Recurring_Order, setRecurring_Order] = useState(null);
    const [LPG_Price, setLPG_Price] = useState(null);
    const [GST, setGST] = useState(null);
    const [Consumer_Price, setConsumer_Price] = useState(null);
    const [Discount, setDiscount] = useState(null);
    const [Price_after_discount, setPrice_after_discount] = useState(null);
    const [Delivery_Charges, setDelivery_Charges] = useState(null);
    const [Security_Deposit, setSecurity_Deposit] = useState(null);
    const [Total_Bill, setTotal_Bill] = useState(null);
    // Pricing State End Here
    // Modals Function Start Here
    const [digicashOtpModal, setDigicashOtpModal] = useState(false);
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [OrderConfirmationdetails, setOrderConfirmationdetails] = useState(null);

    const handleOpen = () => {
        if (!Delivery_Date) {
            toast.error("Please enter a Delivery Date!");
        } else if (Number(Recurring_Order) === 0) {
            toast.error("Recurring Order must be greater than 0");
        } else if (Number(Recurring_Order) <= 0) {
            toast.error("Recurring Order must be greater than 0");
        } else if (!Recurring_Order) {
            toast.error("Please enter a Recurring Order!");
        } else if (quantity === 0) {
            toast.error("Please Add Cylinder!");
        } else if (!paymentMethod) {
            toast.error("Please Select a Payment Method!");
        } else {
            setOpen(true);

            let data = {
                deliverydate: Delivery_Date,
                recurringorder: Recurring_Order,
                itemname: itemlist[0]?.name,
                quantity: quantity,
                totalbill: Total_Bill,
                address: selectaddress.address,
                paymentmethod: paymentMethod,
            }

            setOrderConfirmationdetails(data)
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const plusHandlerAndMinus = (value) => {
        if (quantity === 0 && value === -1) {
            setQuantity(0);
        } else {
            setQuantity(quantity + value);
        }
    };

    const handlePaymentMethodChange = (event) => {
        // console.log(event.target.value);
        setPaymentMethod(event.target.value);
    };

    useEffect(() => {
        if (currentprices && selectaddress) {
            const FilterObject = currentprices.find(price => price.city === selectaddress.city);
            if (FilterObject) {
                setCurrectCityObject(FilterObject);
                // Update other state variables based on FilterObject

                setLPG_Price(FilterObject?.price * quantity);
                setGST(FilterObject?.price / 100 * FilterObject?.gstTax);
                setConsumer_Price(FilterObject?.price / 100 * FilterObject?.gstTax + FilterObject?.price * quantity);
                setDiscount(FilterObject?.price / 100 * FilterObject?.discount);
                setPrice_after_discount(FilterObject?.price / 100 * FilterObject?.gstTax + FilterObject?.price * quantity - FilterObject?.price / 100 * FilterObject?.discount);
                setDelivery_Charges(FilterObject?.deliveryCharges * quantity);
                setSecurity_Deposit(FilterObject?.securityDeposit);
                setTotal_Bill(FilterObject?.price / 100 * FilterObject?.gstTax + FilterObject?.price * quantity - FilterObject?.price / 100 * FilterObject?.discount + FilterObject?.deliveryCharges + FilterObject?.securityDeposit);
            }
        }
    }, [currentprices, selectaddress, quantity]);

    const resHandler = (data) => {
        // console.log("Error in CreateOrder:", data?.data?.statusCode);
        if (data?.data?.statusCode === 200) {
            setConfirmationModal(true);
            handleClose();
        } else {
            setOpen(false)
        }
    }

    const CreateNewOrder = () => {

        let formData = {
            customerId: authuserdetail?.customer?.customerId,
            itemId: 1,
            appType: "Web",
            invoiceType: "SecurityDeposit",
            isRecurring: true,
            numOfDays: Number(Recurring_Order),
            total: Total_Bill,
            customerDetailAddressId: selectaddress?.id,
            paymentMethodId: Number(paymentMethod),
            qty: quantity,
            requiredDate: Delivery_Date,
            securityDeposit: Security_Deposit,
            price: LPG_Price,
            mid: "8047317",
            deviceId: navigator.userAgent,
            cardNo: "NA",
            currentPricingId: CurrectCityObject?.pricingId
        }

        dispatch(CreateOrder(formData, resHandler))
    }

    const [Otp, setOtp] = useState("");

    const handleChange = (newValue) => {
        setOtp(newValue);
    };

    const handleDigicashOtpModalClose = () => {
        setDigicashOtpModal(false);
    };

    const handleVerifyClick = () => {
        setDigicashOtpModal(false);
    };

    const handleConfirmationModalClose = () => {
        setConfirmationModal(false);

        navigate('/')

    };

    const matchingPaymentMethod = paymentmethodslist.find(method => method.id === Number(OrderConfirmationdetails?.paymentmethod));

    const getCurrentDate = () => {
        const today = new Date();
        const yyyy = today.getFullYear();
        const mm = String(today.getMonth() + 1).padStart(2, '0');
        const dd = String(today.getDate()).padStart(2, '0');
        return `${yyyy}-${mm}-${dd}`;
    };

    return (
        <>

            {/* Order Confirmation Modal Start Here */}
            <Modal
                open={open}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Card
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 800,
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        borderRadius: 10,
                    }}
                >
                    <Typography
                        id="modal-title"
                        variant="h6"
                        sx={{
                            textAlign: "center",
                            color: "#1591c7",
                            fontWeight: "bold",
                            fontSize: "30px",
                            marginBottom: "60px",
                        }}
                    >
                        Order Confirmation
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: "#fff",
                            backgroundColor: "#dc0000",
                        }}
                    >
                        <CloseIcon />
                    </IconButton>

                    <Box mt={2} mb={5}>
                        <Stack direction="row" justifyContent="space-between" p={2}>
                            <Typography sx={{ color: "#818181" }}>Item</Typography>
                            <Typography sx={{ color: "#1591c7" }}>
                                {OrderConfirmationdetails?.itemname}
                            </Typography>
                        </Stack>
                        <Divider variant="middle" flexItem />
                        <Stack direction="row" justifyContent="space-between" p={2}>
                            <Typography sx={{ color: "#818181" }}>Quantity</Typography>
                            <Typography sx={{ color: "#1591c7" }}>{OrderConfirmationdetails?.quantity}</Typography>
                        </Stack>
                        <Divider variant="middle" flexItem />
                        <Stack direction="row" justifyContent="space-between" p={2}>
                            <Typography sx={{ color: "#818181" }}>
                                Current Delivery Date
                            </Typography>
                            <Typography sx={{ color: "#1591c7" }}>{OrderConfirmationdetails?.deliverydate}</Typography>
                        </Stack>
                        <Divider variant="middle" flexItem />
                        <Stack direction="row" justifyContent="space-between" p={2}>
                            <Typography sx={{ color: "#818181" }}>
                                No.of Days for Refill
                            </Typography>
                            <Typography sx={{ color: "#1591c7" }}>{OrderConfirmationdetails?.recurringorder} Days</Typography>
                        </Stack>
                        <Divider variant="middle" flexItem />
                        <Stack direction="row" justifyContent="space-between" p={2}>
                            <Typography sx={{ color: "#818181" }}>
                                Total Bill Amount
                            </Typography>
                            <Typography sx={{ color: "#1591c7" }}>Rs {OrderConfirmationdetails?.totalbill}</Typography>
                        </Stack>
                        <Divider variant="middle" flexItem />
                        <Stack p={2}>
                            <Typography sx={{ color: "#818181", fontSize: "15px" }}>
                                Delivery Address
                            </Typography>
                            <Typography sx={{ color: "#1591c7" }}>
                                {OrderConfirmationdetails?.address}
                            </Typography>
                        </Stack>
                        <Stack p={2}>
                            <Typography sx={{}}>Payment Mode</Typography>
                            <Typography sx={{ color: "#1591c7", fontSize: "15px" }}>
                                {matchingPaymentMethod?.paymentMethod}
                            </Typography>
                        </Stack>
                    </Box>
                    <Typography
                        sx={{
                            color: "#1591c7",
                            fontSize: "20px",
                            fontWeight: "bold",
                            textAlign: "center",
                        }}
                    >
                        Do you want to place this order?
                    </Typography>
                    <Stack direction="row" justifyContent="center" spacing={7} p={4}>
                        <Button
                            variant="contained"
                            sx={{
                                width: "200px",
                                height: "50px",
                                borderRadius: "20px",
                                backgroundColor: "green",
                                "&:hover": {
                                    backgroundColor: "darkgreen",
                                },
                            }}
                            onClick={CreateNewOrder}
                        >
                            Yes
                        </Button>
                        <Button
                            variant="contained"
                            sx={{
                                width: "200px",
                                height: "50px",
                                borderRadius: "20px",
                                backgroundColor: "red",
                                "&:hover": {
                                    backgroundColor: "darkred",
                                },
                            }}
                            onClick={handleClose}
                        >
                            No
                        </Button>
                    </Stack>
                </Card>
            </Modal>
            {/* Order Confirmation Modal End Here */}

            {/* Thank you for your purchase Modal Start Here */}
            <Modal
                open={confirmationModal}
                aria-labelledby="third-modal-title"
                aria-describedby="third-modal-description"
            >
                <Card
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 800,
                        boxShadow: 24,
                        borderRadius: 10,
                        p: 4,
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            mb: 2,
                        }}
                    >
                        <img
                            className="blue_logo"
                            src={checkedcylinder}
                            alt="Order Placed"
                            style={{ width: "100px", height: "100px" }}
                        />
                    </Box>
                    <Typography
                        id="third-modal-title"
                        variant="h4"
                        sx={{ textAlign: "center", color: "#1591c7", fontWeight: "bold" }}
                    >
                        Thank you for your purchase
                    </Typography>
                    <Typography
                        id="third-modal-description"
                        sx={{ mt: 2, textAlign: "center", fontSize: "20px" }}
                    >
                        Your order {createorderdetail?.orderId} has been recieved
                    </Typography>
                    <Typography id="third-modal-description" sx={{ textAlign: "center" }}>
                        You will get a confirmation shortly
                    </Typography>

                    <Stack marginTop={5}>
                        <Stack direction="row" justifyContent="space-between">
                            <Typography color="#818181">Quantity</Typography>
                            <Typography color="#1591c7">{OrderConfirmationdetails?.quantity}</Typography>
                        </Stack>
                        <Stack direction="row" justifyContent="space-between" marginTop={3}>
                            <Typography color="#818181">Delivery Date</Typography>
                            <Typography color="#1591c7">{OrderConfirmationdetails?.deliverydate}</Typography>
                        </Stack>
                        <Stack direction="row" justifyContent="space-between" marginTop={3}>
                            <Typography color="#818181">Payment Mode</Typography>
                            <Typography color="#1591c7">{matchingPaymentMethod?.paymentMethod}</Typography>
                        </Stack>
                        <Stack direction="row" justifyContent="space-between" marginTop={3}>
                            <Typography color="#818181">Total Bill Amount</Typography>
                            <Typography color="#1591c7">Rs {OrderConfirmationdetails?.totalbill}</Typography>
                        </Stack>
                        <Stack direction="row" justifyContent="space-between" marginTop={3}>
                            <Typography color="#818181">Delivery Address</Typography>
                            <Stack>
                                <Typography color="#1591c7">{OrderConfirmationdetails?.address} </Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Typography sx={{ textAlign: "center", marginTop: "20px", fontSize: "20px" }}>Kindly show the QR code at the time of delivery</Typography>
                    <Box mt={4} textAlign="center">
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: "primary",
                                width: "70%",
                                height: "50px",
                                borderRadius: "20px",
                            }}
                            onClick={handleConfirmationModalClose}
                        >
                            Back To Homes
                        </Button>
                    </Box>
                </Card>
            </Modal>
            {/* Thank you for your purchase Modal End Here */}

            {/* DIGICASH Modal Start Here */}
            <Modal
                open={digicashOtpModal}
                aria-labelledby="second-modal-title"
                aria-describedby="second-modal-description"
            >
                <Card
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 800,
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        borderRadius: 10,
                        p: 4,
                    }}
                >
                    <Typography
                        id="second-modal-title"
                        variant="h3"
                        sx={{ textAlign: "center", color: "#2b499a", fontWeight: "bold" }}
                    >
                        DIGICASH
                    </Typography>
                    <Typography
                        id="second-modal-description"
                        variant="h5"
                        sx={{ textAlign: "center", color: "#2b499a", fontWeight: "bold" }}
                    >
                        Card Number Verification
                    </Typography>
                    <Typography
                        id="second-modal-description"
                        sx={{ textAlign: "center", color: "#2b499a", fontSize: "25px" }}
                    >
                        Enter the OTP sent to +923362203426
                    </Typography>
                    <MuiOtpInput
                        value={Otp}
                        onChange={handleChange}
                        length={4}
                        p={4}
                        sx={{ borderRadius: "20px" }}
                    />
                    <Typography sx={{ textAlign: "center", color: "#2b499a" }}>
                        Please wait for your verification code 00:19
                    </Typography>
                    <Stack direction="row" justifyContent="center">
                        <Typography
                            sx={{ textAlign: "center", color: "#2b499a", fontSize: "20px" }}
                        >
                            Didn't receive OTP?
                        </Typography>
                        <Button
                            sx={{
                                color: "#2b499a",
                            }}
                        >
                            Resend
                        </Button>
                    </Stack>
                    <Box mt={4} textAlign="center">
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: "#2d4c9c",
                                width: "70%",
                                height: "50px",
                                borderRadius: "20px",
                            }}
                            onClick={handleVerifyClick}
                        >
                            Verify
                        </Button>
                    </Box>
                </Card>
            </Modal>
            {/* DIGICASH Modal End Here */}

            <div className="view_order_detail_div">
                <div className="view_order_detail_inner_header_div">
                    <div className="view_order_detail_inner_header_left_div">
                        <div className="view_order_detail_inner_header_left_headind_div">
                            {itemlist[0]?.name}
                        </div>
                        <div className="view_order_detail_inner_header_left_text_div">
                            {itemlist[0]?.instructions}
                        </div>
                        <div className="view_order_detail_inner_header_left_price_div">
                            RS <span>{CurrectCityObject?.price}</span>
                        </div>
                    </div>
                    <div className="view_order_detail_inner_header_right_div">
                        <img src={cylinder_img} alt="" />
                    </div>
                </div>

                <div className="view_order_detail_inner_body_div">
                    <Card sx={{ boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)" }}>
                        <CardContent>

                            <div className="view_order_detail_inner_body_inner_section_div">
                                <div className="view_order_detail_inner_body_inner_section_heading_div">
                                    Delivery Date
                                </div>
                                <div>
                                    <input type="date" id="" min={getCurrentDate()} value={Delivery_Date} onChange={(e) => setDelivery_Date(e.target.value)} />
                                </div>
                            </div>
                            <Divider sx={{ marginTop: "15px", marginBottom: "15px" }} />

                            <div className="view_order_detail_inner_body_inner_section_div">
                                <div>
                                    <div style={{ fontSize: ".9rem", color: "#1591c7" }}>
                                        Please specify number of days for
                                    </div>
                                    <div className="view_order_detail_inner_body_inner_section_heading_div">
                                        Recurring Order
                                    </div>
                                </div>
                                <div>
                                    <input
                                        type="number"
                                        id=""
                                        value={Recurring_Order}
                                        onChange={(e) => setRecurring_Order(e.target.value)}
                                        min="1"
                                    />
                                </div>
                            </div>
                            <Divider sx={{ marginTop: "15px", marginBottom: "15px" }} />

                            <div className="view_order_detail_inner_body_inner_section_div">
                                <div className="view_order_detail_inner_body_inner_section_heading_div">
                                    Quantity
                                </div>
                                <div>
                                    <div className="main_add_quantity_div">
                                        <div>
                                            <FaMinus onClick={() => plusHandlerAndMinus(-1)} />
                                        </div>
                                        <div>{quantity}</div>
                                        <div>
                                            <FaPlus onClick={() => plusHandlerAndMinus(1)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Divider sx={{ marginTop: "15px", marginBottom: "15px" }} />

                            <div className="view_order_detail_inner_body_inner_section_div">
                                <div className="view_order_detail_inner_body_inner_section_heading_div">
                                    Payment
                                </div>
                                <div></div>
                            </div>
                            <br />
                            <div className="view_order_detail_inner_body_payment_section_div">
                                {paymentmethodslist.map((payment, index) => (
                                    <div key={index}>
                                        <label htmlFor={payment.id}>
                                            <input
                                                type="radio"
                                                name="Payment"
                                                id={payment.id}
                                                value={payment.id}
                                                // checked={paymentMethod === payment.paymentMethod}
                                                onChange={handlePaymentMethodChange}
                                            />
                                            {payment.paymentMethod}
                                        </label>
                                    </div>
                                ))}
                            </div>
                            <Divider sx={{ marginTop: "30px" }} />

                            <div className="main_order_details_div">
                                <div
                                    style={{
                                        color: "#0A74B9",
                                        fontWeight: "600",
                                        marginTop: "15px",
                                    }}
                                >
                                    Order Details
                                </div>
                            </div>

                            <TableContainer>
                                <Table aria-label="order details table">
                                    <TableHead sx={{ height: "5px" }}>
                                        <TableRow>
                                            <TableCell
                                                align="right"
                                                sx={{
                                                    borderBottom: "2px solid black",
                                                }}
                                            ></TableCell>
                                            <TableCell
                                                align="right"
                                                sx={{
                                                    fontSize: "15px",
                                                    fontWeight: "bold",
                                                    borderBottom: "2px solid black",
                                                }}
                                            >
                                                Unit Price
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                                sx={{
                                                    fontSize: "15px",
                                                    fontWeight: "bold",
                                                    borderBottom: "2px solid black",
                                                }}
                                            >
                                                Qty
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                                sx={{
                                                    fontSize: "15px",
                                                    fontWeight: "bold",
                                                    borderBottom: "2px solid black",
                                                }}
                                            >
                                                Total
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        <TableRow>
                                            <TableCell component="th" scope="row">LPG Price</TableCell>
                                            <TableCell align="right">{CurrectCityObject?.price}</TableCell>
                                            <TableCell align="right">{quantity}</TableCell>
                                            <TableCell align="right">{LPG_Price}</TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell component="th" scope="row">GST ({CurrectCityObject?.gstTax + "%"})</TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right">{GST}</TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell component="th" scope="row">Consumer Price</TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right">{Consumer_Price}</TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell component="th" scope="row">Discount ({CurrectCityObject?.discount + "%"})</TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right">{Discount}</TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell component="th" scope="row">Price after discount</TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right">{Price_after_discount}</TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell component="th" scope="row">Delivery Charges</TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right"></TableCell>
                                            <TableCell align="right">{Delivery_Charges}</TableCell>
                                        </TableRow>

                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Divider sx={{ height: "1px", backgroundColor: "black" }} />

                            <Stack
                                direction="row"
                                sx={{
                                    justifyContent: "space-between",
                                    marginTop: "15px",
                                }}
                            >
                                <Typography>Security Deposit</Typography>
                                <Typography>{Security_Deposit}</Typography>
                            </Stack>

                            <Stack
                                direction="row"
                                sx={{
                                    justifyContent: "space-between",
                                    marginTop: "15px",
                                }}
                            >
                                <Typography sx={{ fontWeight: "bold" }}>
                                    Total Bill
                                </Typography>
                                <Typography sx={{ fontWeight: "bold" }}>Rs {Total_Bill}</Typography>
                            </Stack>

                            <Stack sx={{ marginTop: "15px" }}>
                                <Typography sx={{ color: "#1591c7", fontSize: "16px" }}>
                                    Delievery Address
                                </Typography>
                                <Typography sx={{ color: "#818181" }}>
                                    {selectaddress?.address}
                                </Typography>
                            </Stack>

                        </CardContent>
                    </Card>

                    <div className="main_proceed_to_checkout_div">
                        <Button variant="contained" onClick={handleOpen}>
                            Proceed to Checkout
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}


export default New_cylinder
